// @flow

export default function sortDeletedPrivacyPolicySections(
    privacyPolicy,
    deletedClassification,
    deletedSubClassification,
) {
    let sortedPrivacyPolicy = {};
    // Wenn der Hauptbaustein gelöscht wird
    if (deletedSubClassification === 0) {
        Object.keys(privacyPolicy).map((hierarchicalClassification) => (
            // Alle Bausteine die über dem Gelöschten sind, werden um ein Reduziert
            hierarchicalClassification > deletedClassification
                ? sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [parseInt(hierarchicalClassification) - 1]: {
                        ...privacyPolicy[hierarchicalClassification],
                    },
                }
                : sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [hierarchicalClassification]: {
                        ...privacyPolicy[hierarchicalClassification],
                    },
                }
        ));

        // Die Werte der Bausteine werden angepasst, die zuvor im State verschoben wurde
        Object.keys(sortedPrivacyPolicy).map((classification) => (
            Object.keys(sortedPrivacyPolicy[classification]).map((subClassification) => (
                sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [classification]: {
                        ...sortedPrivacyPolicy[classification],
                        [subClassification]: {
                            ...sortedPrivacyPolicy[classification][subClassification],
                            hierarchicalClassification: parseInt(classification),
                        },
                    },
                }
            ))
        ));
    }

    // Wenn ein Sub-Baustein gelöscht wird
    if (deletedSubClassification !== 0) {
        Object.keys(privacyPolicy).map((classification) => (
            Object.keys(privacyPolicy[classification]).map((subClassification) => (
                // Alle Sub-Bausteine die über dem Gelöschten Baustein sind werden um eins Reduziert
                parseInt(classification, 10) === deletedClassification && subClassification > deletedSubClassification
                    ? sortedPrivacyPolicy = {
                        ...sortedPrivacyPolicy,
                        [classification]: {
                            ...sortedPrivacyPolicy[classification],
                            [parseInt(subClassification, 10) - 1]: {
                                ...privacyPolicy[classification][subClassification],
                                hierarchicalSubClassification: parseInt(subClassification, 10) - 1,
                            },
                        },
                    }
                    : sortedPrivacyPolicy = {
                        ...sortedPrivacyPolicy,
                        [classification]: {
                            ...sortedPrivacyPolicy[classification],
                            [subClassification]: {
                                ...privacyPolicy[classification][subClassification],
                            },
                        },
                    }
            ))
        ));
    }

    return sortedPrivacyPolicy;
}
