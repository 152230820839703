// @flow

import React, {useEffect, useReducer} from "react";
import {Link} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import Spinner from "../../scss/img/spinner.gif";
import {loadAllPrivacyPolicySectionsApiCall} from "../../Api/PrivacyPolicySectionApi";
import {
    initialPrivacyPolicyOverviewSections,
    privacyPolicySectionsOverviewReducer,
} from "../reducer/privacyPolicySectionReducer";

export default function PrivacyPolicySectionOverview(): ?$JSXIntrinsics<HTMLTableElement> {
    const [privacyPolicySections, dispatchPrivacyPolicySections] = useReducer(privacyPolicySectionsOverviewReducer, initialPrivacyPolicyOverviewSections());

    useEffect(() => {
        if (!privacyPolicySections.arePrivacyPolicySectionsLoaded) {
            loadPrivacyPolicySections();
        }

        return() => {
            dispatchPrivacyPolicySections({});
        }
    }, [privacyPolicySections.arePrivacyPolicySectionsLoaded]);

    async function loadPrivacyPolicySections(): void {
        const response = await loadAllPrivacyPolicySectionsApiCall();

        dispatchPrivacyPolicySections({
            type: 'setPrivacyPolicySections',
            status: response.status,
            privacyPolicySections: response.privacyPolicySections,
        });
    }

    if (!privacyPolicySections.arePrivacyPolicySectionsLoaded) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return (
        <div>
            <h1>Datenschutzübersicht</h1>
            <Link to={`/privacy-policy/form`}>Bearbeiten</Link>
            <h2>Verantwortlicher</h2>
            <div>Musterfirma-Name</div>
            <div>Musterfirma-Straße</div>
            <div>31785 Hameln, Deutschland</div>
            <div>Telefonnummer: +49 5151 940 157</div>
            <div>E-Mail-Adresse: info@synfi.de</div>
            <br />
            <div>Geschäftsführer: Fabian Simon</div>
            <div>Registernummer: HRB 220 030</div>
            <div>Registergericht: Amtsgericht Hannover</div>
            <br />
            <div>UmsatzsteuerID: DE330785248</div>
            <div>Unseren Datenschutzbeauftragten erreichen Sie unter:</div>
            <div>datenschutz@synfi.de</div>
            <br />
            <div>Stand: 10.07.2020</div>
            <br />
            <Overview privacyPolicySectionList={privacyPolicySections.privacyPolicySections} />
        </div>
    );

}

type TableContentProps = {
    privacyPolicySectionList: [PrivacyPolicySection[]],
};

function Overview({privacyPolicySectionList,}: TableContentProps): ReactDOM$HTMLElementJSXIntrinsic<PrivacyPolicySectionRecord>[] {
    return(
        Object.keys(privacyPolicySectionList).map((index: [], key) => (
            <PrivacyPolicySectionRecord
                key={key}
                privacyPolicySection={privacyPolicySectionList[index]}
            />
        ))
    );
}

type PrivacyPolicySectionRecordProps = {
    privacyPolicySection: PrivacyPolicySection,
};

function PrivacyPolicySectionRecord({privacyPolicySection,}: PrivacyPolicySectionRecordProps): ReactDOM$HTMLElementJSXIntrinsic<PrivacyPolicySectionRecordContent>[] {
    return(
        Object.keys(privacyPolicySection).map((key: string) => (
            <PrivacyPolicySectionRecordContent
                key={privacyPolicySection[key].privacyPolicySectionId}
                privacyPolicySection={privacyPolicySection[key]}
            />
        ))
    );
}

function PrivacyPolicySectionRecordContent({privacyPolicySection,}: PrivacyPolicySectionRecordProps): $JSXIntrinsics<HTMLTableRowElement> {
    return(
        <>
            <div>
                <div className="hierachicalClassification">
                    {privacyPolicySection.hierarchicalSubClassification > 0 ? null : (privacyPolicySection.hierarchicalClassification + 1) + "."}
                </div>
                <div className="sectionContent">
                    <ReactMarkdown children={privacyPolicySection.sectionContent} />
                </div>
            </div>
        </>
    );
}
