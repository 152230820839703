// @flow

import React, {useEffect, useReducer} from "react";
import {Redirect, Route} from "react-router-dom";
import NotAuthorizedErrorBoundary from "./Authorization/NotAuthorizedErrorBoundary";
import Forbidden from "./Authorization/Forbidden";
import NotFound from "./NotFound";
import Surface from "./Surface/Surface";
import {aboutMeApiCall} from "./Api/AuthorizationApi";

const initialUserInformation = {
    isAuthorizationFinished: false,
    isAuthenticated: false,
    isAuthorized: false,
    identifier: null,
    role: null,
};

function userInformationReducer(state, action) {
    switch (action.type) {
        case 200:
            return {
                isAuthorizationFinished: true,
                isAuthenticated: true,
                isAuthorized: action.isAuthorized,
                identifier: action.identifier,
                role: action.role,
            };
        case 401:
            return {
                isAuthorizationFinished: true,
                isAuthenticated: false,
                isAuthorized: false,
                identifier: action.identifier,
                role: action.role,
            };
        default:
            return initialUserInformation;
    }
}

type PrivateRouteProps = {
    exact: boolean,
    path: string,
    component: React$StatelessFunctionalComponent<{}>,
    legitRoles: [],
}

export default function PrivateRoute({exact, path, component, legitRoles}: PrivateRouteProps): void {
    const [userInformation, dispatchUserInformation] = useReducer(userInformationReducer, initialUserInformation);

    useEffect(() => {
        async function autorise() {
            const response = await aboutMeApiCall();

            dispatchUserInformation({
                type: response.status,
                identifier: response.identifier,
                role: response.role,
                isAuthorized: legitRoles.includes(response.role),
            });
        }

        autorise();

        return() => {
            dispatchUserInformation({});
        }
    }, [component, path, legitRoles]);

    // The useReducer state ensures, that all necessary values for routing could be set by the useEffect
    if (!userInformation.isAuthorizationFinished) {
        return null;
    }

    if (!userInformation.isAuthenticated) {
        return (
            <Redirect to="/login" />
        );
    }

    if (!exact) {
        return (
            <NotAuthorizedErrorBoundary>
                <Route component={NotFound} />
            </NotAuthorizedErrorBoundary>
        );
    }

    if (!userInformation.isAuthorized) {
        return (
            <NotAuthorizedErrorBoundary>
                <Route component={Forbidden}/>
            </NotAuthorizedErrorBoundary>

        );
    }

    // Routes to the called resource
    return (
        <NotAuthorizedErrorBoundary>
            <Surface
                exact={exact}
                path={path}
                component={component}
                userIdentifier={userInformation.identifier}
                userRole={userInformation.role}
            />
        </NotAuthorizedErrorBoundary>
    );
};
