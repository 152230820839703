// @flow

import hasUnsavedPrivacyPolicySections from "./privacyPolicyChangeValidation";

export default function buildProjectCommands(project: ProjectState): ProjectCommand[] {
    const savedProject = project.savedProject;
    const unsavedProject = project.unsavedProject;
    const savedSections = project.savedProject ? project.savedProject.privacyPolicySections : null;
    const unsavedSections = project.unsavedProject.privacyPolicySections;

    if (project.newProject) {
        return [
            {
                command: 'create_project',
                payload: unsavedProject,
            },
        ];
    }

    let commands: ProjectCommand[] = [];

    if (project.unsavedProject.domain !== project.savedProject.domain) {
        commands.push({
            command: 'change_project_domain',
            payload: {
                projectId: savedProject.projectId,
                domain: unsavedProject.domain,
            },
        });
    }

    if (project.unsavedProject.customerId !== project.savedProject.customerId) {
        commands.push({
            command: 'change_project_customer_id',
            payload: {
                projectId: savedProject.projectId,
                customerId: unsavedProject.customerId,
            },
        });
    }

    if (project.unsavedProject.aliasDomains !== project.savedProject.aliasDomains) {
        commands.push({
            command: 'change_project_alias_domains',
            payload: {
                projectId: savedProject.projectId,
                aliasDomains: unsavedProject.aliasDomains,
            },
        });
    }

    if (project.unsavedProject.projectTypeId !== project.savedProject.projectTypeId) {
        commands.push({
            command: 'change_project_project_type_id',
            payload: {
                projectId: savedProject.projectId,
                projectTypeId: unsavedProject.projectTypeId,
            },
        });
    }

    if (project.unsavedProject.klaroConsentManager !== project.savedProject.klaroConsentManager) {
        commands.push({
            command: 'change_project_klaro_consent_manager',
            payload: {
                projectId: savedProject.projectId,
                klaroConsentManager: unsavedProject.klaroConsentManager,
            },
        });
    }

    if (project.unsavedProject.gitRepository !== project.savedProject.gitRepository) {
        commands.push({
            command: 'change_project_git_repository',
            payload: {
                projectId: savedProject.projectId,
                gitRepository: unsavedProject.gitRepository,
            },
        });
    }

    //  Wenn der Wert von 'pushSectionsMustChangeCommand' bool true annimmt, wird das Command mit dem Topic
    // 'change_project_privacy_policy_sections' in das Commands-Array gepusht.
    let pushSectionsMustChangeCommand = hasUnsavedPrivacyPolicySections(savedSections, unsavedSections);

    if (pushSectionsMustChangeCommand) {
        commands.push({
            command: 'change_project_privacy_policy_sections',
            payload: {
                projectId: savedProject.projectId,
                privacyPolicySections: unsavedProject.privacyPolicySections,
            },
        });
    }

    return commands;
};
