// @flow

import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import {logoutCall} from "../Api/AuthenticationApi";
import {ReactComponent as HeaderImage} from '../scss/img/header_logo.svg';

type HeaderProps = {
    userIdentifier: string,
}

export default function Header({userIdentifier}: HeaderProps) {
    const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);

    async function logout() {
        const response = await logoutCall();

        if (response.status === 200) {
            setIsLoggedOut(true);
        }
    }

    if (isLoggedOut) {
        return <Redirect to="/login"/>;
    }

    return (
        <header className="header">
            <div className="header__logo">
                <HeaderImage />
            </div>
            <div className="header__user">
                <span className="user-name">{userIdentifier}</span>
                <button className="btn__logout" onClick={logout} />
            </div>
        </header>
    );
}
