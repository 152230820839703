// @flow

import React from "react";
import Header from "./Header";

import SideNav from "./SideNav";
import Content from "./Content";

type SurfaceProps = {
    exact: boolean,
    path: string,
    component: React$StatelessFunctionalComponent<{}>,
    userIdentifier: string,
    userRole: string,
}

export default function Surface({exact, path, component, userIdentifier, userRole}: SurfaceProps) {
    const urlSectors = path.split('/')

    return (
        <>
            <Header userIdentifier={userIdentifier} />
            <div className="container container__flex">
                <SideNav navLocation={urlSectors[1]} userRole={userRole} />
                <div className="content">
                    <div className="content__inner">
                        <Content exact={exact} path={path} component={component} />
                    </div>
                </div>
            </div>
        </>
    );
}
