// @flow

import React from "react";
import {Route} from "react-router-dom";

type ContentProps = {
    exact: boolean,
    path: string,
    component: React$Component<{}>,
}

export default function Content({exact, path, component}: ContentProps) {
    return (
        <div>
            <Route exact={exact} path={path} component={component} />
        </div>
    );
}
