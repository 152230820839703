// @flow

/**
 * @summary Gibt die Default-Unterabschnitte für die DSE des Projektes zurück 'projectsDefaultSubSections'
 * Für neu angelegte Projekte
 */
export const getProjectsDefaultPrivacyPolicySubSections = (formSections: PrivacyPolicySections): PrivacyPolicySection[] => {
    let projectsDefaultSubSections: {}|PrivacyPolicySection[] = {};

    const classifications = getHierarchicalClassificationsOfDefaultMainSections(
        formSections.defaultSections.mainSections,
        formSections.defaultCustomRequiredSections.mainSections
    );

    // Default-Unterabschnitte die aufgrund der Default-Hauptabschnitte der DSE gespeichert werden müssen
    classifications.map((mainHierarchicalClassification: string) => {
        for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.defaultSections.subSections)) {
            if (subSection.hierarchicalClassification === mainHierarchicalClassification) {
                projectsDefaultSubSections[sectionId] = subSection;
            }
        }

        for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.defaultCustomRequiredSections.subSections)) {
            if (subSection.hierarchicalClassification === mainHierarchicalClassification) {
                projectsDefaultSubSections[sectionId] = subSection;
            }
        }
    });

    return projectsDefaultSubSections;
};

/**
 * @summary Gibt die einzublendenden Default-Bausteine zurück 'fadeInSections'
 * Für neu angelegte und aus der Datenbank abgefragte Projekte
 */
export const getDefaultFadeInSections = (formSections: PrivacyPolicySections): PrivacyPolicyFadeInSections => {
    let fadeInSections: PrivacyPolicyFadeInSections = {
        optionalMainSections: {...formSections.optionalSections.mainSections},
        optionalSubSections: {},
        optionalCustomRequiredMainSections: {...formSections.optionalCustomRequiredSections.mainSections},
        optionalCustomRequiredSubSections: {},
    };

    const classifications = getHierarchicalClassificationsOfDefaultMainSections(
        formSections.defaultSections.mainSections,
        formSections.defaultCustomRequiredSections.mainSections
    );

    // Auslesen einzublendender Unterabschnitte der Default-Hauptabschnitte der DSE des Projektes
    classifications.map((mainHierarchicalClassification: string) => {
        for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.optionalSections.subSections)) {
            if (subSection.hierarchicalClassification === mainHierarchicalClassification) {
                fadeInSections.optionalSubSections = {
                    ...fadeInSections.optionalSubSections,
                    [sectionId]: subSection,
                };
            }
        }

        for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.optionalCustomRequiredSections.subSections)) {
            if (subSection.hierarchicalClassification === mainHierarchicalClassification) {
                fadeInSections.optionalCustomRequiredSubSections = {
                    ...fadeInSections.optionalCustomRequiredSubSections,
                    [sectionId]: subSection,
                };
            }
        }
    });

    return fadeInSections;
};

/**
 * @summary Gibt einzublendende Unterabschnitte zurück 'fadeInSections'
 * Für ein geladenes Projekt
 * Optionale Unterabschnitte, die bereits in der DSE des Projektes gespeichert sind
 */
export const getProjectsSelectedSubSections = (
    projectSections: PrivacyPolicySection[],
    fadeInSections: PrivacyPolicyFadeInSections,
): PrivacyPolicyFadeInSections => {
    for (const [sectionId: string, section: PrivacyPolicySection] of Object.entries(projectSections)) {
        if (section.isSubSection && section.isOptional) {
            // Hinzufügen der ausgewählten optionalen Unterabschnitte
            if (!section.isCustomizable) {
                fadeInSections.optionalSubSections = {
                    ...fadeInSections.optionalSubSections,
                    [sectionId]: section,
                };
            }

            // Hinzufügen der ausgewählten optionalen und anzupassenden Unterabschnitte
            if (section.isCustomizable) {
                fadeInSections.optionalCustomRequiredSubSections = {
                    ...fadeInSections.optionalCustomRequiredSubSections,
                    [sectionId]: section,
                };
            }
        }
    }

    return fadeInSections;
};

/**
 * @summary Gibt einzublendende Unterabschnitte zurück 'fadeInSections'
 * Für ein geladenes Projekt
 * Hauptabschnitte die in der DSE des Projektes enthalten sind, können optionale Unterabschnitte enthalten
 */
export const getProjectsFadeInSubSections = (
    formSections: PrivacyPolicySections,
    projectSections: PrivacyPolicySection[],
    fadeInSections: PrivacyPolicyFadeInSections,
): PrivacyPolicyFadeInSections => {
    let mainClassifications: string[] = [];

    // Zuweisen der hierarchicalClassifications aller Hauptabschnitte der DSE des Projektes
    for (const [sectionId: string, section: PrivacyPolicySection] of Object.entries(projectSections)) {
        if (!mainClassifications.includes(section.hierarchicalClassification) && !section.isSubSection) {
            mainClassifications.push(section.hierarchicalClassification);
        }
    }

    mainClassifications.sort().map((mainClassification: string) => {
        for (const [sectionId: string, section: PrivacyPolicySection] of Object.entries(formSections.optionalSections.subSections)) {
            if (section.hierarchicalClassification === mainClassification && !projectSections.hasOwnProperty(sectionId)) {
                fadeInSections.optionalSubSections = {
                    ...fadeInSections.optionalSubSections,
                    [sectionId]: section,
                };
            }
        }

        for (const [sectionId: string, section: PrivacyPolicySection] of Object.entries(formSections.optionalCustomRequiredSections.subSections)) {
            if (section.hierarchicalClassification === mainClassification && !projectSections.hasOwnProperty(sectionId)) {
                fadeInSections.optionalCustomRequiredSubSections = {
                    ...fadeInSections.optionalCustomRequiredSubSections,
                    [sectionId]: section,
                };
            }
        }
    });

    return fadeInSections;
};

/**
 * @summary Gibt die 'hierarchicalClassification' aller Default-Hauptabschnitte
 */
const getHierarchicalClassificationsOfDefaultMainSections = (
    optionalMainSections: PrivacyPolicySection[],
    optionalCustomRequiredMainSections: PrivacyPolicySection[],
): string[] => {
    let includedClassifications = [];

    // Nummern der hierarchischen Klassifizierungen der Default-Hauptabschnitte gruppieren
    for (const [sectionId: string, section: PrivacyPolicySection] of Object.entries(optionalMainSections)) {
        if (!includedClassifications.includes(section.hierarchicalClassification) && !section.isSubSection) {
            includedClassifications.push(section.hierarchicalClassification);
        }
    }

    // Nummern der hierarchischen Klassifizierungen der anpassbaren Default-Hauptabschnitte hinzufügen
    for (const [sectionId: string, section: PrivacyPolicySection] of Object.entries(optionalCustomRequiredMainSections)) {
        if (!includedClassifications.includes(section.hierarchicalClassification) && !section.isSubSection) {
            includedClassifications.push(section.hierarchicalClassification);
        }
    }

    return includedClassifications.sort();
};
