// @flow

import React from 'react';
import {RadioButtons, Select, StaticText} from "./FormInputElements";
import {SubmitButton} from "./FormDefaultElements";
import CustomerForm from "../../Customer/CustomerForm";
import {formatDateStringToUTC} from "../helper/dateFormatting";

type StaticFormBodyProps = {
    domainError: string,
    formElements: {
        domain: ProjectFormTextElement,
        customerId: ProjectFormSelectElement,
        aliasDomains: ProjectFormTextElement,
        lastPrivacyPolicyUpdateDateProps: ProjectFormTextElement,
        projectTypeId: ProjectFormRadioElement,
        klaroConsentManager: ProjectFormRadioElement,
        gitRepository: ProjectFormTextElement,
    },
    unsavedProject: Project,
    changeValue: Function,
}

export default function StaticFormBody(
    {domainError, formElements, unsavedProject, changeValue}: StaticFormBodyProps
): $JSXIntrinsics<HTMLFormElement> {
    return (
        <div>
            <Domain
                key={formElements.domain.name}
                domainError={domainError}
                formElement={formElements.domain}
                value={unsavedProject.domain ?? ''}
                onChange={changeValue}
            />
            <div className="form__add-project__two">
                <div className="item-66 form__choose_customer">
                    <CustomerId
                        key={formElements.customerId.name}
                        formElement={formElements.customerId}
                        value={unsavedProject.customerId ?? ''}
                        onChange={changeValue}
                    />
                    {/*<CustomerForm match={{params: ''}} />*/}
                </div>
                <div className="item-33">
                    <AliasDomains
                        key={formElements.aliasDomains.name}
                        formElement={formElements.aliasDomains}
                        value={unsavedProject.aliasDomains ?? ''}
                        onChange={changeValue}
                    />
                    <LastPrivacyPolicyUpdateDate
                        key={formElements.lastPrivacyPolicyUpdateDate.name}
                        updateDate={unsavedProject.lastPrivacyPolicyUpdateDate ?? ''}
                    />
                    <ProjectTypeId
                        key={formElements.projectTypeId.name}
                        formElementKey={formElements.projectTypeId.label}
                        formElement={formElements.projectTypeId}
                        checkedId={unsavedProject.projectTypeId}
                        onChange={changeValue}
                    />
                    <KlaroConsentManager
                        key={formElements.klaroConsentManager.name}
                        formElementKey={formElements.klaroConsentManager.label}
                        formElement={formElements.klaroConsentManager}
                        checkedValue={unsavedProject.klaroConsentManager}
                        onChange={changeValue}
                    />
                    <GitRepository
                        key={formElements.gitRepository.name}
                        formElement={formElements.gitRepository}
                        value={unsavedProject.gitRepository ?? ''}
                        onChange={changeValue}
                    />
                </div>
            </div>
        </div>
    );
};

type DomainProps = {
    domainError: string,
    formElement: {},
    value: string,
    onChange: Function,
};

function Domain({domainError, formElement, value, onChange}: DomainProps): $JSXIntrinsics<HTMLDivElement> {
    return (
        <div>
            <div className="form__add-project__one">
                <StaticText key={formElement.name} textElement={formElement} value={value} onChange={onChange}/>
                <SubmitButton />
            </div>
            {/*TODO: Das Ding muss auch rausfliegen sobald ein Error-Handling useReducer implementiert wird*/}
            <p>{domainError}</p>
        </div>
    );
}

type CustomerIdProps = {
    formElement: {},
    value: string,
    onChange: Function,
};

function CustomerId({formElement, value, onChange}: CustomerIdProps): $JSXIntrinsics<HTMLFormElement> {
    return <Select key={formElement.name} selectElement={formElement} value={value} onChange={onChange}/>;
}

type AliasDomainsProps = {
    formElement: {},
    value: string,
    onChange: Function,
};

function AliasDomains({formElement, value, onChange}: AliasDomainsProps): $JSXIntrinsics<HTMLFormElement> {
    return <StaticText key={formElement.name} textElement={formElement} value={value} onChange={onChange}/>;
}

type LastPrivacyPolicyUpdateDateProps = {
    updateDate: string,
};

function LastPrivacyPolicyUpdateDate({updateDate}: LastPrivacyPolicyUpdateDateProps): $JSXIntrinsics<HTMLSpanElement> {
    return (
        <span className="two__update">
            Aktueller Stand: <span>{updateDate ? formatDateStringToUTC(new Date(updateDate)) : updateDate}</span>
        </span>
    );
}

type ProjectTypeIdProps = {
    formElementKey: string,
    formElement: {},
    checkedId: boolean,
    onChange: Function,
};

function ProjectTypeId(
    {formElementKey, formElement, checkedId, onChange}: ProjectTypeIdProps
): $JSXIntrinsics<HTMLFormElement>[] {
    return (
        <RadioButtons
            formElementKey={formElementKey}
            key={formElement.name}
            radioElement={formElement}
            checkedValue={checkedId}
            onChange={onChange}
        />
    );
}

type KlaroConsentManagerProps = {
    formElementKey: string,
    formElement: {},
    checkedValue: boolean,
    onChange: Function,
};

function KlaroConsentManager(
    {formElementKey, formElement, checkedValue, onChange}: KlaroConsentManagerProps
): $JSXIntrinsics<HTMLFormElement>[] {
    return (
        <RadioButtons
            formElementKey={formElementKey}
            key={formElement.name}
            radioElement={formElement}
            checkedValue={checkedValue}
            onChange={onChange}
        />
    );
}

type GitRepositoryProps = {
    formElement: {},
    value: string,
    onChange: Function,
};

function GitRepository({formElement, value, onChange}: GitRepositoryProps): $JSXIntrinsics<HTMLFormElement> {
    return <StaticText key={formElement.name} textElement={formElement} value={value} onChange={onChange}/>;
}
