// @flow

export default function sortPrivacyPolicy(
    privacyPolicy,
    srcClassification,
    destClassification,
) {
    let sortedPrivacyPolicy = {};
    const srcSection        = privacyPolicy[srcClassification];
    delete privacyPolicy[srcClassification];

    // Wenn der Source-Baustein in der Reihenfolge über dem Destination-Baustein liegt
    if (srcClassification > destClassification) {
        Object.keys(privacyPolicy).map((hierarchicalClassification) => (
            // Alle Bausteine die über dem Destination-Baustein liegen werden
            // um eins erhöht um platz für den Source-Baustein zu schaffen
            hierarchicalClassification < srcClassification && hierarchicalClassification >= destClassification
                ? sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [parseInt(hierarchicalClassification) + 1]: privacyPolicy[hierarchicalClassification],
                }
                : sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [hierarchicalClassification]: privacyPolicy[hierarchicalClassification],
                }
        ));
    }

    // Wenn der Source-Baustein in der Reihenfolge unter dem Destination-Baustein liegt
    if (srcClassification < destClassification) {
        Object.keys(privacyPolicy).map((hierarchicalClassification) => (
            // Alle Bausteine die Unter dem Destination-Baustein liegen werden um eins Reduziert
            hierarchicalClassification > srcClassification && hierarchicalClassification <= destClassification
                ? sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [parseInt(hierarchicalClassification) - 1]: privacyPolicy[hierarchicalClassification],
                }
                : sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [hierarchicalClassification]: privacyPolicy[hierarchicalClassification],
                }
        ));
    }

    // Source-Baustein wird an die neue Destination position gesetzt
    sortedPrivacyPolicy = {
        ...sortedPrivacyPolicy,
        [destClassification]: srcSection,
    };

    // Die hierarchicalClassifciation der Bausteine werden aktualisiert
    Object.keys(sortedPrivacyPolicy).map((classification) => (
        Object.keys(sortedPrivacyPolicy[classification]).map((subClassification) => (
            sortedPrivacyPolicy = {
                ...sortedPrivacyPolicy,
                [classification]: {
                    ...sortedPrivacyPolicy[classification],
                    [subClassification]: {
                        ...sortedPrivacyPolicy[classification][subClassification],
                        hierarchicalClassification: parseInt(classification),
                    },
                },
            }
        ))
    ));

    return sortedPrivacyPolicy;
}
