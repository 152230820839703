// @flow

export type AuthenticationResponse = {
    errors: boolean,
    errorMessages: [],
    status: ?number,
}

export type LogoutResponse = {
    error: boolean,
    errorMessages: [],
    status: ?number,
}

export async function loginCall(loginParams: {email: string, password: string}): Promise<AuthenticationResponse> {
    let response;

    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailAddress: loginParams.email,
                password: loginParams.password,
            }),
        });

        const data = await response.json();

        return {
            errors: data.errors,
            errorMessages: data.errorMessages,
            status: response.status,
        }
    } catch (e) {
        return {
            errors: true,
            errorMessages: e.errorMessages,
            status: response ? response.status : null,
        }
    }
}

export async function logoutCall(): Promise<LogoutResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();

        return {
            errors: data.errors,
            errorMessage: data.errorMessages,
            status: response.status,
        }

    } catch (e) {
        return {
            errors: true,
            errorMessages: e.errorMessages,
            status: response ? response.status : null,
        }
    }
}