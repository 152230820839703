// @flow

export async function loadUsersApiCall(): Promise<UsersResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/user/all`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            users: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        }
    } catch (e) {
        return {
            users: [],
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function loadUserApiCall(id: string): Promise<UserResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/user/id/${id}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            user: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        }
    } catch (e) {
        return {
            user: null,
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        }
    }
}
