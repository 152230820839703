// @flow

import NotAuthorizedError from "../../Authorization/NotAuthorizedError";

export const initialProjects = (): ProjectOverviewState => {
    return {
        projectsLoaded: false,
        projects: [],
    };
};

export const projectOverviewReducer = (state: ProjectOverviewState, action: ProjectOverviewAction): ProjectOverviewState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setProjects':
            return {
                projectsLoaded: true,
                projects: action.projects,
            }
        case 'cleanup':
            return {
                ...initialProjects(),
            }
        default:
            return {
                // TODO: Hier kann sicherlich noch nen Errorhandling hin, doch erstmal nur Endlosschleifen verhindern
                ...state,
                projectsLoaded: true,
            };
    }
};

export const initialProject = (): ProjectState => {
    return {
        newProject: false,
        newProjectId: null,
        knownProjectFetched: false,
        savedProject: null,
        unsavedProject: null,
    };
};

export const projectReducer = (state: ProjectState, action: ProjectAction): ProjectState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setNewProject':
            return {
                ...state,
                newProject: true,
                newProjectId: null,
                knownProjectFetched: false,
                savedProject: null,
                unsavedProject: {
                    projectId: null,
                    customerId: null,
                    domain: null,
                    aliasDomains: null,
                    projectTypeId: null,
                    gitRepository: null,
                    klaroConsentManager: null,
                    privacyPolicySections: {
                        ...action.defaultMainSections,
                        ...action.defaultCustomRequiredMainSections,
                        ...action.defaultSubSections,
                    },
                    lastPrivacyPolicyUpdateDate: null,
                    lastPrivacyPolicyIntegrationDate: null,
                    currentPrivacyPolicyIntegrationSucceeded: false,
                    createdOn: null,
                    editor: null,
                },
            };
        case 'setNewProjectCreated':
            return {
                ...initialProject(),
                newProject: true,
                newProjectId: action.projectId,
            };
        case 'setKnownProject':
            return {
                ...state,
                newProject: false,
                newProjectId: null,
                knownProjectFetched: true,
                savedProject: action.project,
                unsavedProject: action.project,
            };
        case 'transferUpdatedProjectValues':
            return {
                ...state,
                savedProject: {
                    ...state.unsavedProject
                },
            };
        case 'updatePrivacyPolicy':
            return {
                ...state,
                unsavedProject: {
                    ...state.unsavedProject,
                    privacyPolicySections: action.updatedSections,

                },
            };
        case 'changePrivacyPolicySectionContent':
            return {
                ...state,
                unsavedProject: {
                    ...state.unsavedProject,
                    privacyPolicySections: {
                        ...state.unsavedProject.privacyPolicySections,
                        [action.sectionId]: {
                            ...state.unsavedProject.privacyPolicySections[action.sectionId],
                            sectionContent: action.sectionContent
                        },
                    },
                },
            };
        case 'changeProjectValue':
            return {
                ...state,
                unsavedProject: {
                    ...state.unsavedProject,
                    [action.prop]: action.value,
                },
            };
        case 'cleanup':
            return {
                ...initialProject(),
            };
        default:
            return {
                // TODO: Hier sollte ein Errorhandling hin, UnknownActionErrorBoundary
                ...state,
            };
    }
};

export const initialProjectHtmlFormElements = (): ProjectHtmlFormElements => {
    return {
        domain: {
            orderBehavior: 'static',
            type: 'text',
            name: 'domain',
            label: 'Domain',
            readonly: false,
            cssClass: '',
        },
        customerId: {
            orderBehavior: 'static',
            type: 'select',
            name: 'customerId',
            label: 'Inhaber/Firma',
            options: null,
            cssClass: '',
        },
        aliasDomains: {
            orderBehavior: 'static',
            type: 'text',
            name: 'aliasDomains',
            label: 'Alias-Domains',
            readonly: false,
            cssClass: 'add-project__two--inner',
        },
        lastPrivacyPolicyUpdateDate: {
            orderBehavior: 'static',
            type: 'text',
            name: 'lastPrivacyPolicyUpdateDate',
            label: 'Aktueller Stand',
            readonly: true,
            cssClass: '',
        },
        projectTypeId: {
            orderBehavior: 'static',
            type: 'radio',
            name: 'projectTypeId',
            label: 'Projekt-Typ',
            radioButtons: null,
            cssClass: '',
        },
        klaroConsentManager: {
            orderBehavior: 'static',
            type: 'radio',
            name: 'klaroConsentManager',
            label: 'Klaro Consent Manager',
            radioButtons: [
                {
                    label: 'Ja',
                    value: '1',
                },
                {
                    label: 'Nein',
                    value: '0',
                },
            ],
            cssClass: '',
        },
        gitRepository: {
            orderBehavior: 'static',
            type: 'text',
            name: 'gitRepository',
            label: 'Git Repository',
            readonly: false,
            cssClass: 'add-project__two--inner',
        },
        privacyPolicySections: null,
        privacyPolicyFadeInSections: null,
    };
};

export const projectHtmlFormElementsReducer = (state: ProjectHtmlFormElements, action: ProjectHtmlFormElementsAction): ProjectHtmlFormElements => {
    switch (action.type) {
        case 'setCustomerOptions':
            return {
                ...state,
                customerId: {
                    ...state.customerId,
                    options: action.options,
                },
            };
        case 'setProjectTypeRadioButtons':
            return {
                ...state,
                projectTypeId: {
                    ...state.projectTypeId,
                    radioButtons: action.radioButtons,
                },
            };
        case 'setPrivacyPolicySections':
            return {
                ...state,
                privacyPolicySections: action.sections,
            };
        case 'updatePrivacyPolicyFadeInSections':
            return {
                ...state,
                privacyPolicyFadeInSections: {
                    ...state.privacyPolicyFadeInSections,
                    ...action.fadeInSections,
                },
            };
        case 'cleanup':
            return {
                ...initialProjectHtmlFormElements(),
            };
        default:
            return {
                // TODO: Hier sollte ein Errorhandling hin, UnknownActionErrorBoundary
                ...state,
            };
    }
};
