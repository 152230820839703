// @flow

import React from "react";

declare type FormTopicProps = {
    formTopics: {[key: string]: string},
    selectedTopic: string,
    hierarchicalClassification: number,
    hierarchicalSubClassification: number,
    changeValue: Function,
}

export default function FormTopicDropDown({
    formTopics,
    selectedTopic,
    changeValue,
    hierarchicalClassification,
    hierarchicalSubClassification,
}: FormTopicProps) {
    return (
        <>
            <select
                name="formTopic"
                value={selectedTopic ? selectedTopic : ''}
                onChange={changeValue.bind(
                    this,
                    hierarchicalClassification,
                    hierarchicalSubClassification,
                )}>
                <option value="" hidden>Bitte auswählen</option>
                {Object.keys(formTopics).map((topicId: string, key: number) => (
                    <option key={key} value={formTopics[topicId].formTopicId}>{formTopics[topicId].formTopic}</option>
                ))}
            </select>
        </>
    );
}
