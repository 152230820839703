// @flow

export default function buildPrivacyPolicyCommands(
    savedPrivacyPolicy: {[key: number]: {[key: number]: PrivacyPolicySection}},
    unsavedPrivacyPolicy: {[key: number]: {[key: number]: PrivacyPolicySection}},
): Array {
    const commandArray = Object.keys(unsavedPrivacyPolicy).map((classification: string) => {
        return Object.keys(unsavedPrivacyPolicy[classification]).map((subClassification: string) => {
                let commandArray = [];

                if (
                    savedPrivacyPolicy[classification][subClassification].formTopic
                    !== unsavedPrivacyPolicy[classification][subClassification].formTopic
                ) {
                    commandArray.push({
                        'command': 'change_privacy_policy_form_topic',
                        'payload': {
                            'privacyPolicySectionId': savedPrivacyPolicy[classification][subClassification].privacyPolicySectionId,
                            'formTopic': unsavedPrivacyPolicy[classification][subClassification].formTopic,
                        }
                    });
                }

                if (
                    savedPrivacyPolicy[classification][subClassification].sectionTitle
                    !== unsavedPrivacyPolicy[classification][subClassification].sectionTitle
                ) {
                    commandArray.push({
                        'command': 'change_privacy_policy_section_title',
                        'payload': {
                            'privacyPolicySectionId': savedPrivacyPolicy[classification][subClassification].privacyPolicySectionId,
                            'sectionTitle': unsavedPrivacyPolicy[classification][subClassification].sectionTitle,
                        }
                    });
                }

                if (savedPrivacyPolicy[classification][subClassification].sectionContent !== unsavedPrivacyPolicy[classification][subClassification].sectionContent) {
                    const result = unsavedPrivacyPolicy[classification][subClassification].sectionContent.match('.*{{.+}}.*')

                    if (result !== null) {
                        commandArray.push({
                            'command': 'change_privacy_policy_is_customizable',
                            'payload': {
                                'privacyPolicySectionId': savedPrivacyPolicy[classification][subClassification].privacyPolicySectionId,
                                'isCustomizable': true,
                            }
                        });
                    }

                    if (result === null) {
                        commandArray.push({
                            'command': 'change_privacy_policy_is_customizable',
                            'payload': {
                                'privacyPolicySectionId': savedPrivacyPolicy[classification][subClassification].privacyPolicySectionId,
                                'isCustomizable': false,
                            }
                        });
                    }

                    commandArray.push({
                        'command': 'change_privacy_policy_section_content',
                        'payload': {
                            'privacyPolicySectionId': savedPrivacyPolicy[classification][subClassification].privacyPolicySectionId,
                            'sectionContent': unsavedPrivacyPolicy[classification][subClassification].sectionContent,
                        }
                    });
                }

                if (
                    savedPrivacyPolicy[classification][subClassification].isOptional
                    !== unsavedPrivacyPolicy[classification][subClassification].isOptional
                ) {
                    commandArray.push({
                        'command': 'change_privacy_policy_is_optional',
                        'payload': {
                            'privacyPolicySectionId': savedPrivacyPolicy[classification][subClassification].privacyPolicySectionId,
                            'isOptional': unsavedPrivacyPolicy[classification][subClassification].isOptional,
                        }
                    });
                }

                if (
                    savedPrivacyPolicy[classification][subClassification].isSubSection
                    !== unsavedPrivacyPolicy[classification][subClassification].isSubSection
                ) {
                    commandArray.push({
                        'command': 'change_privacy_policy_is_sub_section',
                        'payload': {
                            'privacyPolicySectionId': savedPrivacyPolicy[classification][subClassification].privacyPolicySectionId,
                            'isSubSection': unsavedPrivacyPolicy[classification][subClassification].isSubSection,
                        }
                    });
                }

                return commandArray;
            }
        )
    });

    const commands = [];

    commandArray.map((item) => (
        item.map((item2) => (
            Object.keys(item2).map((key) => (
                commands.push(item2[key])
            ))
        ))
    ));

    return commands;
}
