// @flow

import NotAuthorizedError from "../../Authorization/NotAuthorizedError";

export const initialCustomers = (): CustomerOverviewState => {
    return {
        areCustomersLoaded: false,
        customers: [],
    };
};

export const customerOverviewReducer = (state: CustomerOverviewState, action: CustomerOverviewAction): CustomerOverviewState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setCustomers':
            return {
                areCustomersLoaded: true,
                customers: action.customers,
            }
        case 'cleanup':
            return {
                ...initialCustomers(),
            }
        default:
            return {
                // TODO: Hier kann sicherlich noch nen Errorhandling hin, doch erstmal nur Endlosschleifen verhindern
                ...state,
                areCustomersLoaded: true,
            };
    }
};

export const initialCustomer = (): CustomerFormState => {
    return {
        customerLoaded: false,
        legalStructureIdsLoaded: false,
        legalStructureIds: [],
        savedCustomer: {
            customerId: "",
            legalStructureId: "",
            legalStructure: "",
            companyName: "",
            customerAddress: {
                street: "",
                houseNumber: "",
                country: "",
                postalCode: "",
                addressLocality: "",
            },
            phoneNumber: "",
            companyEmailAddress: "",
            managingDirector: "",
            registerNumber: "",
            registerCourt: "",
            registeredOn: "",
            vatId: "",
            dataProtectionOfficerEmailAddress: "",
        },
        unsavedCustomer: {
            customerId: "",
            legalStructureId: "",
            legalStructure: "",
            companyName: "",
            customerAddress: {
                street: "",
                houseNumber: "",
                country: "",
                postalCode: "",
                addressLocality: "",
            },
            phoneNumber: "",
            companyEmailAddress: "",
            managingDirector: "",
            registerNumber: "",
            registerCourt: "",
            registeredOn: "",
            vatId: "",
            dataProtectionOfficerEmailAddress: "",
        },
        formTextLabels: {
            companyName: {
                label: "Firma",
            },
            street: {
                label: "Straße",
            },
            houseNumber: {
                label: "Nr.",
            },
            country: {
                label: "Land",
            },
            postalCode: {
                label: "PLZ",
            },
            addressLocality: {
                label: "Ort",
            },
            phoneNumber: {
                label: "Telefonnummer",
            },
            companyEmailAddress: {
                label: "E-Mail-Adresse",
            },
            managingDirector: {
                label: "Geschäftsführer",
            },
            registerNumber: {
                label: "Registernummer",
            },
            registerCourt: {
                label: "Registergericht",
            },
            vatId: {
                label: "Umsatzsteuer-ID",
            },
            dataProtectionOfficerEmailAddress: {
                label: "Datenschutzbeauftragter E-Mail-Adresse",
            },
        },
        customerAddress: {
            street: {
                label: "Straße",
            },
            houseNumber: {
                label: "Nr.",
            },
            country: {
                label: "Land",
            },
            postalCode: {
                label: "PLZ",
            },
            addressLocality: {
                label: "Ort",
            },
        },
        customerResponse: {
            createCustomer: {
                body: {},
                errorMessage: "",
            },
            email: {
                body: {},
                errorMessage: "",
            },
            firstName: {
                body: {},
                errorMessage: "",
            },
            lastName: {
                body: {},
                errorMessage: "",
            },
            password: {
                body: {},
                errorMessage: "",
            },
            role: {
                body: {},
                errorMessage: "",
            },
        }
    };
};

export const customerFormReducer = (state: CustomerFormState, action: CustomerFormAction): CustomerFormState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setlegalStructureIds':
            return {
                ...state,
                legalStructureIdsLoaded: true,
                legalStructureIds: action.legalStructureIds,
            }
        case 'setCustomerValues':
            return {
                ...state,
                customerLoaded: true,
                savedCustomer: {
                    ...state.savedCustomer,
                    customerId: action.receivedValues.customerId,
                    legalStructureId: action.receivedValues.legalStructureId,
                    legalStructure: action.receivedValues.legalStructure,
                    companyName: action.receivedValues.companyName,
                    phoneNumber: action.receivedValues.phoneNumber,
                    companyEmailAddress: action.receivedValues.companyEmailAddress,
                    managingDirector: action.receivedValues.managingDirector,
                    registerNumber: action.receivedValues.registerNumber,
                    registerCourt: action.receivedValues.registerCourt,
                    registeredOn: action.receivedValues.registeredOn,
                    vatId: action.receivedValues.vatId,
                    dataProtectionOfficerEmailAddress: action.receivedValues.dataProtectionOfficerEmailAddress,
                    customerAddress: {
                        street: action.receivedValues.customerAddress.street,
                        houseNumber: action.receivedValues.customerAddress.houseNumber,
                        country: action.receivedValues.customerAddress.country,
                        postalCode: action.receivedValues.customerAddress.postalCode,
                        addressLocality: action.receivedValues.customerAddress.addressLocality,
                    }
                },
                unsavedCustomer: {
                    ...state.unsavedCustomer,
                    customerId: action.receivedValues.customerId,
                    legalStructureId: action.receivedValues.legalStructureId,
                    legalStructure: action.receivedValues.legalStructure,
                    companyName: action.receivedValues.companyName,
                    phoneNumber: action.receivedValues.phoneNumber,
                    companyEmailAddress: action.receivedValues.companyEmailAddress,
                    managingDirector: action.receivedValues.managingDirector,
                    registerNumber: action.receivedValues.registerNumber,
                    registerCourt: action.receivedValues.registerCourt,
                    registeredOn: action.receivedValues.registeredOn,
                    vatId: action.receivedValues.vatId,
                    dataProtectionOfficerEmailAddress: action.receivedValues.dataProtectionOfficerEmailAddress,
                    customerAddress: {
                        street: action.receivedValues.customerAddress.street,
                        houseNumber: action.receivedValues.customerAddress.houseNumber,
                        country: action.receivedValues.customerAddress.country,
                        postalCode: action.receivedValues.customerAddress.postalCode,
                        addressLocality: action.receivedValues.customerAddress.addressLocality,
                    }
                },
            }
        case 'setValue':
            return {
                ...state,
                unsavedCustomer: {
                    ...state.unsavedCustomer,
                    [action.property]: action.value,
                },
            }
        case 'setCustomerAddressValue':
            return {
                ...state,
                unsavedCustomer: {
                    ...state.unsavedCustomer,
                    customerAddress: {
                        ...state.unsavedCustomer.customerAddress,
                        [action.property]: action.value,
                    }
                },
            }
        case 'setResponse':
            return {
                ...state,
                customerResponse: {
                    ...state.customerResponse,
                    ...action.customerResponse,
                }
            }
        case 'cleanup':
            return {
                ...initialCustomer(),
            }
        default:
            return {
                // TODO: Hier kann sicherlich noch nen Errorhandling hin, doch erstmal nur Endlosschleifen verhindern
                ...state,
                customerLoaded: true,
            };
    }
};
