// @flow

export default function buildHierarchicalClassificationCommands(
    srcClassification: number,
    destClassification: number,
    unsavedPrivacyPolicySections: {[key: number]: {[key: number]: PrivacyPolicySection}},
    ): [] {
    let commands = [];

    if (srcClassification > destClassification) {
        Object.keys(unsavedPrivacyPolicySections).map((classification: string) => (
            Object.keys(unsavedPrivacyPolicySections[classification]).map((subClassification: string) => (
                classification < srcClassification && classification >= destClassification
                    ? commands.push({
                        'command': 'change_privacy_policy_hierarchical_classification',
                        'payload': {
                            'privacyPolicySectionId': unsavedPrivacyPolicySections[classification][subClassification].privacyPolicySectionId,
                            'hierarchicalClassification': parseInt(classification, 10) + 1,
                        }
                    })
                    : null
            ))
        ));
    }

    if (srcClassification < destClassification) {
        Object.keys(unsavedPrivacyPolicySections).map((classification: string) => (
            Object.keys(unsavedPrivacyPolicySections[classification]).map((subClassification: string) => (
                classification > srcClassification && classification <= destClassification
                    ? commands.push({
                        'command': 'change_privacy_policy_hierarchical_classification',
                        'payload': {
                            'privacyPolicySectionId': unsavedPrivacyPolicySections[classification][subClassification].privacyPolicySectionId,
                            'hierarchicalClassification': parseInt(classification, 10) - 1,
                        }
                    })
                    : null
            ))
        ));
    }


    const srcSections = unsavedPrivacyPolicySections[srcClassification];

    Object.keys(srcSections).map((index: number) => (
        commands.push({
            'command': 'change_privacy_policy_hierarchical_classification',
            'payload': {
                'privacyPolicySectionId': srcSections[index].privacyPolicySectionId,
                'hierarchicalClassification': destClassification,
            }
        })
    ));

    return commands;
}
