// @flow

import React from 'react';

const Forbidden = () => (
    <div>
        <h2>403 Forbidden</h2>
    </div>
);

export default Forbidden;
