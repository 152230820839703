// @flow

import {commandActionApi} from "../../Api/CommandApi";

const createNewSection = async (
    isSubSection: boolean,
    dispatch: Function,
    isPrivacyPolicySaved: boolean,
    unsavedPrivacyPolicySections: {[key: number]: {[key: number]: PrivacyPolicySection}},
    savedPrivacyPolicySections: {[key: number]: {[key: number]: PrivacyPolicySection}},
    changePrivacyPolicySavedState: Function,
    event: SyntheticEvent<HTMLFormElement>,
) => {
    if (!isPrivacyPolicySaved) {
        dispatch({
            type: 'setResponse',
            response: "Bitte Speichern Sie das Formular, bevor Sie eine neue Sektion anlegen.",
        });
        return;
    }

    let commands = [];

    commands.push({
        'command': 'create_privacy_policy_section',
        'payload': {
            formTopic: "",
            sectionTitle: "",
            sectionContent: "",
            isOptional: false,
            isCustomizable: false,
            isSubSection: false,
            hierarchicalClassification: event.target.value,
            hierarchicalSubClassification: 0,
            version: 1,
        }
    });

    const classification = Object.keys(unsavedPrivacyPolicySections).length;
    const response       = await commandActionApi(commands, '/privacy-policy/action');

    if (response.error) {
        dispatch({
            type: 'setResponse',
            response: response.errorMessages,
        });
        return
    }

    const newSubSection = {
        privacyPolicySectionId: response.response.createPrivacyPolicySection.body.privacyPolicySectionId,
        formTopic: "",
        sectionTitle: "",
        sectionContent: "",
        isOptional: false,
        isCustomizable: false,
        isSubSection: false,
        hierarchicalClassification: classification,
        hierarchicalSubClassification: 0,
        version: 1,
    };

    let newUnsavedPrivacyPolicySections = {
        ...unsavedPrivacyPolicySections,
        [classification]: {
            0: newSubSection,
        },
    };

    let newSavedPrivacyPolicySections = {
        ...savedPrivacyPolicySections,
        [classification]: {
            0: newSubSection,
        },
    };

    dispatch({
        type: 'setUnsavedPrivacyPolicy',
        receivedValues: newUnsavedPrivacyPolicySections,
    });

    dispatch({
        type: 'setSavedPrivacyPolicy',
        receivedValues: newSavedPrivacyPolicySections,
    });
}

export default createNewSection;
