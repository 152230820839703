// @flow

import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import CustomerForm from "./Customer/CustomerForm";
import CustomerOverview from "./Customer/CustomerOverview";
import GeneralProjectForm from "./Project/Form/GeneralProjectForm";
import Login from "./Authentication/Login";
import NotFound from "./NotFound";
import PrivacyPolicySectionForm from "./PrivacyPolicySection/Form/PrivacyPolicySectionForm";
import PrivacyPolicySectionOverview from "./PrivacyPolicySection/Overview/PrivacyPolicySectionOverview";
import PrivateRoute from "./PrivateRoute";
import ProjectOverview from "./Project/ProjectOverview";
import UserForm from "./User/UserForm";
import UserOverview from "./User/UserOverview";

const all = ['Admin', 'Datenschutz', 'Entwicklung', 'Grafik', 'Marketing'];

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={ProjectOverview} legitRoles={all} />
            <PrivateRoute exact path="/customer/form" component={CustomerForm} legitRoles={all} />
            <PrivateRoute exact path="/customer/form/:customerId" component={CustomerForm} legitRoles={all} />
            <PrivateRoute exact path="/customer/overview" component={CustomerOverview} legitRoles={all} />
            <PrivateRoute exact path="/privacy-policy/overview" component={PrivacyPolicySectionOverview} legitRoles={all} />
            <PrivateRoute
                exact
                path="/privacy-policy/form"
                component={PrivacyPolicySectionForm}
                legitRoles={[
                    'Admin',
                    'Datenschutz',
                    'Marketing',
                ]}
            />
            <PrivateRoute exact path="/project/form" component={GeneralProjectForm} legitRoles={all} />
            <PrivateRoute exact path="/project/form/:projectId" component={GeneralProjectForm} legitRoles={all} />
            <PrivateRoute exact path="/project/overview" component={ProjectOverview} legitRoles={all} />
            <PrivateRoute exact path="/user/form/" component={UserForm} legitRoles={['Admin']} />
            <PrivateRoute exact path="/user/form/:userId" component={UserForm} legitRoles={['Admin']} />
            <PrivateRoute exact path="/user/overview" component={UserOverview} legitRoles={['Admin']} />
            <PrivateRoute exact={false} path="" component={NotFound} legitRoles={all}/>
        </Switch>
    </BrowserRouter>
);

export default Router;
