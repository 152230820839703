// @flow

export const handleSubSectionChanged = (
    checked: boolean,
    selectedSectionId: string,
    sectionProperty: string,
    projectsSections: PrivacyPolicySection[],
    formSections: PrivacyPolicySections,
): ProjectAction => {
    /** 1.1 Unterabschnitt löschen */
    if (!checked) {
        delete projectsSections[selectedSectionId];

        return {
            type: 'updatePrivacyPolicy',
            updatedSections: projectsSections,
        };
    }

    /** 1.2 Unterabschnitt hinzufügen */
    if (sectionProperty === 'optional') {
        // Optionalen Unterabschnitt der DSE des Projektes hinzufügen
        projectsSections[selectedSectionId] = formSections.optionalSections.subSections[selectedSectionId];

        return {
            type: 'updatePrivacyPolicy',
            updatedSections: projectsSections,
        };
    }

    // Optionalen und anzupassenden Unterabschnitt der DSE des Projektes hinzufügen
    projectsSections[selectedSectionId] = formSections.optionalCustomRequiredSections.subSections[selectedSectionId];

    return {
        type: 'updatePrivacyPolicy',
        updatedSections: projectsSections,
    };
};

export const handleMainSectionRemoved = (
    selectedSection: PrivacyPolicySection,
    projectSections: PrivacyPolicySection[],
    fadeInSections: PrivacyPolicyFadeInSections,
    formSections: PrivacyPolicySections,
): {formElementsAction: ProjectHtmlFormElementsAction, projectAction: ProjectAction} => {
    /** 2.2.1 Entfernen auszublendender Unterabschnitte aus 'fadeInSections' (optional & optionalCustomRequired) */
    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.optionalSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            delete fadeInSections.optionalSubSections[sectionId];

            // Wenn der entfernte Unterabschnitt in der DSE des Projektes steht, wird er daraus entfernt
            if (projectSections.hasOwnProperty(sectionId)) {
                delete projectSections[sectionId];
            }
        }
    }

    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.optionalCustomRequiredSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            delete fadeInSections.optionalCustomRequiredSubSections[sectionId];

            // Wenn der entfernte Unterabschnitt in der DSE des Projektes steht, wird er daraus entfernt
            if (projectSections.hasOwnProperty(sectionId)) {
                delete projectSections[sectionId];
            }
        }
    }

    /** 2.2.2 Die Default-Unterabschnitte des Hauptabschnitts aus der DSE entfernen */
    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.defaultSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            delete projectSections[sectionId];
        }
    }

    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.defaultCustomRequiredSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            delete projectSections[sectionId];
        }
    }

    // Den ausgewählten Hauptabschnitt aus der DSE entfernen
    delete projectSections[selectedSection.privacyPolicySectionId];

    return {
        formElementsAction: {
            type: 'updatePrivacyPolicyFadeInSections',
            fadeInSections: fadeInSections,
        },
        projectAction: {
            type: 'updatePrivacyPolicy',
            updatedSections: projectSections,
        },
    };
};

export const handleMainSectionAdded = (
    selectedSection: PrivacyPolicySection,
    projectSections: PrivacyPolicySection[],
    fadeInSections: PrivacyPolicyFadeInSections,
    formSections: PrivacyPolicySections,
): {formElementsAction: ProjectHtmlFormElementsAction, projectAction: ProjectAction} => {
    /** 2.3.1 Neu einzublendende Unterabschnitte den 'fadeInSections' zuweisen (optional & optionalCustomRequired) */
    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.optionalSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            fadeInSections.optionalSubSections[sectionId] = subSection;
        }
    }

    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.optionalCustomRequiredSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            fadeInSections.optionalCustomRequiredSubSections[sectionId] = subSection;
        }
    }

    /** 2.3.2 Den 'projectSections' die Default-Unterabschnitte des Hauptabschnitts zuweisen (default & defaultCustomRequired) */
    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.defaultSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            projectSections[sectionId] = subSection;
        }
    }

    for (const [sectionId: string, subSection: PrivacyPolicySection] of Object.entries(formSections.defaultCustomRequiredSections.subSections)) {
        if (subSection.hierarchicalClassification === selectedSection.hierarchicalClassification) {
            projectSections[sectionId] = subSection;
        }
    }

    // Den ausgewählten Hauptabschnitt in die DSE des Projektes eintragen
    projectSections[selectedSection.privacyPolicySectionId] = selectedSection;

    return {
        formElementsAction: {
            type: 'updatePrivacyPolicyFadeInSections',
            fadeInSections: fadeInSections,
        },
        projectAction: {
            type: 'updatePrivacyPolicy',
            updatedSections: projectSections,
        },
    };
};
