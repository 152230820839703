// @flow

import React, {useEffect, useReducer, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Spinner from "../../scss/img/spinner.gif";
import MarkdownLegend from "./MarkdownLegend";
import PrivacyPolicyPreview from "./PrivacyPolicyPreview";
import PrivacyPolicySubSectionRecords from "./PrivacyPolicySubSectionRecords";
import {initialPrivacyPolicySections, PrivacyPolicySectionFormReducer} from "../reducer/privacyPolicySectionReducer";
import {loadAllPrivacyPolicySectionsApiCall} from "../../Api/PrivacyPolicySectionApi";
import {commandActionApi} from "../../Api/CommandApi";
import {loadOptionValuesApiCall} from "../../Api/HtmlOptionValueApi";
import sortPrivacyPolicy from "../helper/sortPrivacyPolicy";
import buildHierarchicalClassificationCommands from "../helper/privacyPolicyHierarchicalClassificationCommandFactory";
import createNewSection from "../helper/createPrivacyPolicySection";
import deleteSection from "../helper/deletePrivacyPolicySection";
import buildPrivacyPolicyCommands from "../helper/privacyPolicyCommandFactory";
import createNewSubSection from "../helper/createPrivacyPolicySubSection";

export default function PrivacyPolicySectionForm(): ?$JSXIntrinsics<HTMLFormElement> {
    const [state, dispatch] = useReducer(PrivacyPolicySectionFormReducer, initialPrivacyPolicySections);
    const [isPrivacyPolicySaved, setIsPrivacyPolicySaved] = useState<boolean>(false);

    useEffect(() => {
        if (!state.privacyPolicySectionsLoaded) {
            loadPrivacyPolicySections();
            setIsPrivacyPolicySaved(true);
        }

        return () => {
            dispatch({type: 'cleanup',});
        }
        // eslint-disable-next-line
    }, [isPrivacyPolicySaved]);

    async function loadPrivacyPolicySections(): void {
        const response = await loadAllPrivacyPolicySectionsApiCall();

        dispatch({
            type: 'setPrivacyPolicyValues',
            status: response.status,
            receivedValues: response.privacyPolicySections,
        });
    }

    useEffect(() => {
        if (!state.privacyPolicyFormTopicsLoaded) {
            loadFormTopics();
        }

        return () => {
            dispatch({type: 'cleanup',});
        }
        // eslint-disable-next-line
    }, [isPrivacyPolicySaved]);

    async function loadFormTopics(): void {
        const response = await loadOptionValuesApiCall('form-topics');

        dispatch({
            type: 'setFormTopics',
            formTopics: response.values,
        });
    }

    const doSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const commands = buildPrivacyPolicyCommands(
            state.savedPrivacyPolicySections,
            state.unsavedPrivacyPolicySections,
        );

        if (commands.length === 0) return;

        const response = await commandActionApi(commands, '/privacy-policy/action');

        if (response.error) {
            dispatch({
                type: 'setResponse',
                response: response.errorMessages,
            });
            return;
        }

        const privacyPolicySectionsResponse = await loadAllPrivacyPolicySectionsApiCall();

        if (response.error) {
            dispatch({
                type: 'setResponse',
                response: response.errorMessages,
            });
            return;
        }

        dispatch({
            type: 'setPrivacyPolicyValues',
            status: privacyPolicySectionsResponse.status,
            receivedValues: privacyPolicySectionsResponse.privacyPolicySections,
        });

        dispatch({
            type: 'setResponse',
            response: 'Erfolgreich gespeichert.',
        });
    }

    function changeIsPrivacyPolicySavedState(state: boolean) {
        setIsPrivacyPolicySaved(state);
    }

    function changeValue(
        hierarchicalClassification,
        hierarchicalSubClassification,
        event: SyntheticInputEvent<HTMLInputElement>
    ): void {
        if (event.target.value.length > 255 && event.target.name !== 'sectionContent') return;
        const value = event.target.value;
        const checked = event.target.checked;

        dispatch({
            type: 'setValue',
            property: event.target.name,
            value: event.target.value === "on" ? checked : value,
            hierarchicalClassification: hierarchicalClassification,
            hierarchicalSubClassification: hierarchicalSubClassification,
        });
    }

    function trimValue(
        hierarchicalClassification,
        hierarchicalSubClassification,
        event: SyntheticInputEvent<HTMLInputElement>,
    ): void {
        dispatch({
            type: 'setValue',
            property: event.target.name,
            value: event.target.value.trim(),
            hierarchicalClassification: hierarchicalClassification,
            hierarchicalSubClassification: hierarchicalSubClassification,
        });
    }

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,


        // change background colour if dragging
        background: isDragging ? '#9B9B9B' : 'white',


        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#292b2e" : "white",
        padding: grid,
        width: 820,
    });

    const onDragEnd = async (result) => {
        if (result.destination === null) return;
        if (result.source.index === result.destination.index) return;

        const srcClassification = result.source.index;
        const destClassification = result.destination.index;

        const savedPrivacyPolicy = sortPrivacyPolicy(
            {...state.savedPrivacyPolicySections},
            srcClassification,
            destClassification,
        );

        const unsavedPrivacyPolicy = sortPrivacyPolicy(
            {...state.unsavedPrivacyPolicySections},
            srcClassification,
            destClassification,
        );

        dispatch({
            type: 'setSavedPrivacyPolicy',
            receivedValues: savedPrivacyPolicy,
        });

        dispatch({
            type: 'setUnsavedPrivacyPolicy',
            receivedValues: unsavedPrivacyPolicy,
        });

        const commands = buildHierarchicalClassificationCommands(
            srcClassification,
            destClassification,
            state.unsavedPrivacyPolicySections,
        );

        const response = await commandActionApi(commands, '/privacy-policy/action');

        if (response.error) {
            dispatch({
                type: 'setResponse',
                response: response.errorMessages,
            });
        }
    }

    if (!state.privacyPolicySectionsLoaded) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return (
        <div className="form__edit-dse">
            <h1>DSE Vorlage bearbeiten</h1>
            <div className="item-40">
                <MarkdownLegend/>
                <button className="btn__add-dse-a"
                    value={Object.keys(state.unsavedPrivacyPolicySections).length}
                    onClick={createNewSection.bind(
                        this,
                        true,
                        dispatch,
                        isPrivacyPolicySaved,
                        state.unsavedPrivacyPolicySections,
                        state.savedPrivacyPolicySections,
                        changeIsPrivacyPolicySavedState,
                    )}
                >
                    Neuer Hauptabschnitt
                </button>
                <br/>
                <PrivacyPolicyPreview
                    privacyPolicy={state.unsavedPrivacyPolicySections}
                />
                <br/>
            </div>
            <div className="item-60">
                <form onSubmit={doSubmit}>
                    <button type="submit" className="btn__save">Speichern</button>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {Object.keys(state.unsavedPrivacyPolicySections).map((key, index) => (
                                        <Draggable key={key} draggableId={key} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div {...provided.dragHandleProps}>
                                                        <PrivacyPolicySubSectionRecords
                                                            privacyPolicySections={state.unsavedPrivacyPolicySections[key]}
                                                            formTopics={state.privacyPolicyFormTopics}
                                                            unsavedPrivacyPolicySections={state.unsavedPrivacyPolicySections}
                                                            savedPrivacyPolicySections={state.savedPrivacyPolicySections}
                                                            isPrivacyPolicySaved={isPrivacyPolicySaved}
                                                            changeValue={changeValue}
                                                            trimValue={trimValue}
                                                            createNewSubSection={createNewSubSection}
                                                            deleteSection={deleteSection}
                                                            changePrivacyPolicySavedState={changeIsPrivacyPolicySavedState}
                                                            dispatch={dispatch}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </form>
                <div className="errorMessage">
                    {state.privacyPolicySectionsResponse || null}
                </div>
            </div>
        </div>
    );
}
