// @flow

export async function loadProjectsApiCall(): Promise<ProjectsResponse> {
    let response;

    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/project/all`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            projects: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        };
    } catch (e) {
        return {
            projects: [],
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        };
    }
}

export async function loadProjectByIdApiCall(projectId: string): Promise<ProjectResponse> {
    let response;

    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/project/id/${projectId}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            project: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        };
    } catch (e) {
        return {
            project: null,
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        };
    }
}

export async function loadProjectsPrivacyPolicyAsHtmlApiCall(projectId: string): Promise<HtmlExportResponse> {
    let response;

    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/project/privacy-policy-html/${projectId}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/octet-stream',
            },
        });

        const blob = response.blob();

        return {
            htmlFile: blob,
            errors: false,
            errorMessages: [],
            status: response.status,
        };
    } catch (e) {
        return {
            htmlFile: null,
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        };
    }
}
