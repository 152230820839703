// @flow

export default function buildCustomerCommands(safedCustomer: Customer, unsafedCustomer: Customer): Array {
    let commands = [];

    if (safedCustomer.customerId === '') {
        return [{
            'command': 'create_customer',
            'payload': {
                'legalStructureId': unsafedCustomer.legalStructureId,
                'companyName': unsafedCustomer.companyName,
                'street': unsafedCustomer.customerAddress.street,
                'houseNumber': unsafedCustomer.customerAddress.houseNumber,
                'postalCode': unsafedCustomer.customerAddress.postalCode,
                'addressLocality': unsafedCustomer.customerAddress.addressLocality,
                'country': unsafedCustomer.customerAddress.country,
                'phoneNumber': unsafedCustomer.phoneNumber,
                'companyEmailAddress': unsafedCustomer.companyEmailAddress,
                'managingDirector': unsafedCustomer.managingDirector,
                'registerNumber': unsafedCustomer.registerNumber,
                'registerCourt': unsafedCustomer.registerCourt,
                'vatId': unsafedCustomer.vatId,
                'dataProtectionOfficerEmailAddress': unsafedCustomer.dataProtectionOfficerEmailAddress,
            }
        }];
    }

    if (safedCustomer.legalStructureId !== unsafedCustomer.legalStructureId) {
        commands.push({
            'command': 'change_customer_company_legal_structure',
            'payload': {
                'customerId': safedCustomer.customerId,
                'legalStructureId': unsafedCustomer.legalStructureId
            }
        });
    }

    if (safedCustomer.companyName !== unsafedCustomer.companyName) {
        commands.push({
            'command': 'change_customer_company_name',
            'payload': {
                'customerId': safedCustomer.customerId,
                'companyName': unsafedCustomer.companyName
            }
        });
    }

    if (safedCustomer.customerAddress.street !== unsafedCustomer.customerAddress.street) {
        commands.push({
            'command': 'change_customer_company_street',
            'payload': {
                'customerId': safedCustomer.customerId,
                'street': unsafedCustomer.customerAddress.street
            }
        });
    }

    if (safedCustomer.customerAddress.houseNumber !== unsafedCustomer.customerAddress.houseNumber) {
        commands.push({
            'command': 'change_customer_company_house_number',
            'payload': {
                'customerId': safedCustomer.customerId,
                'houseNumber': unsafedCustomer.customerAddress.houseNumber
            }
        });
    }

    if (safedCustomer.customerAddress.postalCode !== unsafedCustomer.customerAddress.postalCode) {
        commands.push({
            'command': 'change_customer_company_postal_code',
            'payload': {
                'customerId': safedCustomer.customerId,
                'postalCode': unsafedCustomer.customerAddress.postalCode
            }
        });
    }

    if (safedCustomer.customerAddress.addressLocality !== unsafedCustomer.customerAddress.addressLocality) {
        commands.push({
            'command': 'change_customer_company_address_locality',
            'payload': {
                'customerId': safedCustomer.customerId,
                'addressLocality': unsafedCustomer.customerAddress.addressLocality
            }
        });
    }

    if (safedCustomer.customerAddress.country !== unsafedCustomer.customerAddress.country) {
        commands.push({
            'command': 'change_customer_company_country',
            'payload': {
                'customerId': safedCustomer.customerId,
                'country': unsafedCustomer.customerAddress.country
            }
        });
    }

    if (safedCustomer.phoneNumber !== unsafedCustomer.phoneNumber) {
        commands.push({
            'command': 'change_customer_company_phone_number',
            'payload': {
                'customerId': safedCustomer.customerId,
                'phoneNumber': unsafedCustomer.phoneNumber
            }
        });
    }

    if (safedCustomer.companyEmailAddress !== unsafedCustomer.companyEmailAddress) {
        commands.push({
            'command': 'change_customer_company_email_address',
            'payload': {
                'customerId': safedCustomer.customerId,
                'companyEmailAddress': unsafedCustomer.companyEmailAddress
            }
        });
    }

    if (safedCustomer.managingDirector !== unsafedCustomer.managingDirector) {
        commands.push({
            'command': 'change_customer_company_managing_director',
            'payload': {
                'customerId': safedCustomer.customerId,
                'managingDirector': unsafedCustomer.managingDirector
            }
        });
    }

    if (safedCustomer.registerNumber !== unsafedCustomer.registerNumber) {
        commands.push({
            'command': 'change_customer_company_register_number',
            'payload': {
                'customerId': safedCustomer.customerId,
                'registerNumber': unsafedCustomer.registerNumber
            }
        });
    }

    if (safedCustomer.registerCourt !== unsafedCustomer.registerCourt) {
        commands.push({
            'command': 'change_customer_company_register_court',
            'payload': {
                'customerId': safedCustomer.customerId,
                'registerCourt': unsafedCustomer.registerCourt
            }
        });
    }

    if (safedCustomer.vatId !== unsafedCustomer.vatId) {
        commands.push({
            'command': 'change_customer_company_vat_id',
            'payload': {
                'customerId': safedCustomer.customerId,
                'vatId': unsafedCustomer.vatId
            }
        });
    }

    if (safedCustomer.dataProtectionOfficerEmailAddress !== unsafedCustomer.dataProtectionOfficerEmailAddress) {
        commands.push({
            'command': 'change_customer_company_data_protection_officer_email_address',
            'payload': {
                'customerId': safedCustomer.customerId,
                'dataProtectionOfficerEmailAddress': unsafedCustomer.dataProtectionOfficerEmailAddress
            }
        });
    }

    return commands;
}
