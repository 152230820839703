// @flow

import React, {useEffect, useReducer} from "react";
import {Redirect} from "react-router-dom";
import Spinner from "../scss/img/spinner.gif";
import {loadUserApiCall} from "../Api/UserApi";
import {loadOptionValuesApiCall} from "../Api/HtmlOptionValueApi";
import {initialUser, userFormReducer} from "./reducer/userReducer";
import {commandActionApi} from "../Api/CommandApi";

type Props = {
    match: {
        params: {
            userId: string,
        },
    },
}

export default function UserForm({match,}: Props): ?$JSXIntrinsics<HTMLFormElement> {
    const [state, dispatch] = useReducer(userFormReducer, initialUser());

    useEffect(() => {
        if (!state.userRolesLoaded) {
            loadUserRoles();
        }

        return () => {
            dispatch({type: 'cleanup',});
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (match.params.userId) {
            loadUser();
        }

        return () => {
            dispatch({type: 'cleanup',});
        }
    }, [match.params.userId]);

    async function loadUserRoles(): void {
        const response = await loadOptionValuesApiCall('roles');

        dispatch({
            type: 'setRoles',
            status: response.status,
            userRoles: response.values,
        });
    }

    async function loadUser(): void {
        const response = await loadUserApiCall(match.params.userId);

        dispatch({
            type: 'setUserValues',
            status: response.status,
            receivedValues: response.user,
        });
    }

    const doSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const commands = buildUserCommands();

        const response = await commandActionApi(commands, '/user/action');

        dispatch({
            type: 'setResponse',
            userResponse: response.response,
        });
    }

    function buildUserCommands(): {} {
        let commands = [];

        if (state.savedUser.userId === '') {
            return [{
                'command': 'create_user',
                'payload': {
                    'emailAddress': state.unsavedUser.emailAddress,
                    'firstName': state.unsavedUser.firstName,
                    'lastName': state.unsavedUser.lastName,
                    'password': state.unsavedUser.password,
                    'role': state.unsavedUser.role,
                }
            }];
        }

        if (state.savedUser.emailAddress !== state.unsavedUser.emailAddress) {
            commands.push({
                'command': 'change_email_address',
                'payload': {
                    'userId': state.savedUser.userId,
                    'emailAddress': state.unsavedUser.emailAddress
                }
            });
        }

        if (state.savedUser.firstName !== state.unsavedUser.firstName) {
            commands.push({
                'command': 'change_user_first_name',
                'payload': {
                    'userId': state.savedUser.userId,
                    'firstName': state.unsavedUser.firstName
                }
            });
        }

        if (state.savedUser.lastName !== state.unsavedUser.lastName) {
            commands.push({
                'command': 'change_user_last_name',
                'payload': {
                    'userId': state.savedUser.userId,
                    'lastName': state.unsavedUser.lastName
                }
            });
        }

        if (state.savedUser.password !== state.unsavedUser.password) {
            commands.push({
                'command': 'change_password',
                'payload': {
                    'userId': state.savedUser.userId,
                    'password': state.unsavedUser.password
                }
            });
        }

        if (state.savedUser.role !== state.unsavedUser.role) {
            commands.push({
                'command': 'change_role',
                'payload': {
                    'userId': state.savedUser.userId,
                    'role': state.unsavedUser.role
                }
            });
        }

        return commands;
    }

    function changeValue(event: SyntheticInputEvent<HTMLInputElement>): void {
        if (event.target.name === 'role' && event.target.value.length !== 36) return;

        if (event.target.name !== 'role' && event.target.value.length > 255) return;

        dispatch({
            type: 'setValue',
            property: event.target.name,
            value: event.target.value,
        });
    }

    function createErrorMessage(key: string) {
        return (
            <div>
                {state.userResponse[key].errorMessage === '' ? null : state.userResponse[key].errorMessage}
            </div>
        );
    }

    if (Object.keys(state.userResponse.createUser.body).length > 0) {
        return <Redirect to={`/user/form/${state.userResponse.createUser.body.userId}`}/>
    }

    /** *** Hodor *** **/
    if (!state.userRolesLoaded || (match.params.userId && !state.userLoaded)) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return (
        <>
            <FormHeader userId={match.params.userId} />
            <form className="form__add-user" onSubmit={doSubmit}>
                {Object.keys(state.formTextLabels).map(key => (
                    <label key={key}>
                        {state.formTextLabels[key].label}
                        <input
                            key={key}
                            name={key}
                            type="text"
                            maxLength="255"
                            value={state.unsavedUser[key]}
                            onChange={changeValue}

                        />
                        {typeof state.userResponse[key] !== 'undefined' ? createErrorMessage(key) : null}
                    </label>
                ))}
                <label>
                    Rolle
                    <select name="role" defaultValue={state.savedUser.role} onChange={changeValue}>
                        <option>Bitte auswählen</option>
                        {state.userRoles.map((userRole: { "id": string, "role": string }, key) => (
                            <option key={key} value={userRole.id}>{userRole.role}</option>
                        ))}
                    </select>
                </label>
                <button className="btn__save">Speichern</button>
            </form>
        </>
    );
}

type FormHeaderProps = {
    userId: 'undefined'|string,
};

function FormHeader({userId}: FormHeaderProps): $JSXIntrinsics<HTMLHeadingElement> {
    return typeof userId !== 'undefined' ? <h1>Benutzer bearbeiten</h1> : <h1>Benutzer anlegen</h1>;
}
