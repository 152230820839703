// @flow

export async function commandActionApi(commands: [], route: string): Promise<CommandActionApiResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + route, {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(commands),
        });

        const data = await response.json();

        return {
            response: data,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
        return {
            response: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
