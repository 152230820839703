// @flow

import React from 'react';
import {render} from 'react-dom';
import Router from "./Router";
import './scss/style.scss';

document.addEventListener('DOMContentLoaded', function (): void {
    const element = document.querySelector('#main');
    if (element){
        render(<Router/>, element);
    } else {
        console.error("#main element not found")
    }
});
