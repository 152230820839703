// @flow

import {commandActionApi} from "../../Api/CommandApi";
import sortPrivacyPolicyForNewSubSection from "./sortPrivacyPolicyForNewSubSection";

const createNewSubSection = async (
    subClassification: number,
    isSubSection: boolean,
    unsavedPrivacyPolicySections: {[key: number]: {[key: number]: PrivacyPolicySection}},
    savedPrivacyPolicySections: {[key: number]: {[key: number]: PrivacyPolicySection}},
    isPrivacyPolicySaved: boolean,
    dispatch: Function,
    changePrivacyPolicySavedState: Function,
    formTopic: string,
    event: SyntheticEvent<HTMLFormElement>,
) => {
    if (!isPrivacyPolicySaved) {
        dispatch({
            type: 'setResponse',
            response: "Bitte speichern Sie das Formular, bevor Sie eine neue Sektion anlegen.",
        });
        return;
    }

    let commands = [];

    commands.push({
        'command': 'create_privacy_policy_section',
        'payload': {
            formTopic: formTopic,
            sectionTitle: "",
            sectionContent: "",
            isOptional: false,
            isCustomizable: false,
            isSubSection: isSubSection,
            hierarchicalClassification: event.target.value,
            hierarchicalSubClassification: !unsavedPrivacyPolicySections[event.target.value][parseInt(
                subClassification,
                10,
            ) - 1].isSubSection
            && unsavedPrivacyPolicySections[event.target.value][parseInt(
                subClassification,
                10,
            ) - 1].hierarchicalSubClassification !== 0
                ? parseInt(subClassification) - 1
                : subClassification,
            version: 1,
        }
    });

    if (
        !unsavedPrivacyPolicySections[event.target.value][parseInt(subClassification, 10) - 1].isSubSection
        && unsavedPrivacyPolicySections[event.target.value][parseInt(
        subClassification,
        10,
        ) - 1].hierarchicalSubClassification !== 0
    ) {
        commands.push({
            'command': 'change_privacy_policy_hierarchical_sub_classification',
            'payload': {
                'privacyPolicySectionId': unsavedPrivacyPolicySections[event.target.value][parseInt(
                    subClassification,
                    10,
                ) - 1].privacyPolicySectionId,
                'hierarchicalSubClassification': parseInt(subClassification, 10),
            }
        });
    }

    const response             = await commandActionApi(commands, '/privacy-policy/action');

    if (response.error) {
        dispatch({
            type: 'setResponse',
            response: response.errorMessages,
        });
        return;
    }

    const newSubClassification = !unsavedPrivacyPolicySections[event.target.value][parseInt(
        subClassification,
        10,
    ) - 1].isSubSection
    && unsavedPrivacyPolicySections[event.target.value][parseInt(
        subClassification,
        10,
    ) - 1].hierarchicalSubClassification !== 0
        ? parseInt(subClassification) - 1 : subClassification;

    const newSubSection = {
        privacyPolicySectionId: response.response.createPrivacyPolicySection.body.privacyPolicySectionId,
        formTopic: formTopic,
        sectionTitle: "",
        sectionContent: "",
        isOptional: false,
        isCustomizable: false,
        isSubSection: isSubSection,
        hierarchicalClassification: event.target.value,
        hierarchicalSubClassification: newSubClassification,
        version: 1,
    };

    let newUnsavedPrivacyPolicySections = sortPrivacyPolicyForNewSubSection(
        unsavedPrivacyPolicySections,
        event.target.value,
        newSubClassification,
    );
    newUnsavedPrivacyPolicySections[event.target.value][newSubClassification] = newSubSection;

    let newSavedPrivacyPolicySections = sortPrivacyPolicyForNewSubSection(
        unsavedPrivacyPolicySections,
        event.target.value,
        newSubClassification,
    );
    newSavedPrivacyPolicySections[event.target.value][newSubClassification] = newSubSection;

    dispatch({
        type: 'setUnsavedPrivacyPolicy',
        receivedValues: newUnsavedPrivacyPolicySections,
    });

    dispatch({
        type: 'setSavedPrivacyPolicy',
        receivedValues: newSavedPrivacyPolicySections,
    });
}

export default createNewSubSection;
