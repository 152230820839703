// @flow

import NotAuthorizedError from "../../Authorization/NotAuthorizedError";

export const initialPrivacyPolicyOverviewSections = (): PrivacyPolicySectionsOverviewState => {
    return {
        arePrivacyPolicySectionsLoaded: false,
        privacyPolicySections: [],
    };
};

export const privacyPolicySectionsOverviewReducer = (
    state: PrivacyPolicySectionsOverviewState,
    action: PrivacyPolicySectionOverviewAction,
): PrivacyPolicySectionsOverviewState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setPrivacyPolicySections':
            return {
                arePrivacyPolicySectionsLoaded: true,
                privacyPolicySections: action.privacyPolicySections,
            }
        case 'cleanup':
            return {
                ...initialPrivacyPolicyOverviewSections(),
            }
        default:
            return {
                ...state,
                arePrivacyPolicySectionsLoaded: true,
            };
    }
};

export const initialPrivacyPolicySections = (): PrivacyPolicySectionsFormState => {
    return {
        privacyPolicySectionsLoaded: false,
        privacyPolicyFormTopicsLoaded: false,
        isPrivacyPolicySaved: false,
        privacyPolicyFormTopics: [],
        savedPrivacyPolicySections: {},
        unsavedPrivacyPolicySections: {},
        privacyPolicySectionsResponse: '',
    };
};

export const PrivacyPolicySectionFormReducer = (
    state: PrivacyPolicySectionsFormState,
    action: PrivacyPolicySectionFormAction,
): PrivacyPolicySectionsFormState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setPrivacyPolicyValues':
            return {
                ...state,
                privacyPolicySectionsLoaded: true,
                savedPrivacyPolicySections: {
                    ...state.savedPrivacyPolicySections,
                    ...action.receivedValues,
                },
                unsavedPrivacyPolicySections: {
                    ...state.unsavedPrivacyPolicySections,
                    ...action.receivedValues,
                },
            }
        case 'setSavedPrivacyPolicy':
            return {
                ...state,
                savedPrivacyPolicySections: {
                    ...action.receivedValues,
                },
            }
        case 'setUnsavedPrivacyPolicy':
            return {
                ...state,
                unsavedPrivacyPolicySections: {
                    ...action.receivedValues,
                },
            }
        case 'setValue':
            return {
                ...state,
                unsavedPrivacyPolicySections: {
                    ...state.unsavedPrivacyPolicySections,
                    [action.hierarchicalClassification]: {
                        ...state.unsavedPrivacyPolicySections[action.hierarchicalClassification],
                        [action.hierarchicalSubClassification]: {
                            ...state.unsavedPrivacyPolicySections[action.hierarchicalClassification][action.hierarchicalSubClassification],
                            [action.property]: action.value,
                        }
                    },
                },
            }
        case 'setFormTopics':
            return {
                ...state,
                privacyPolicyFormTopicsLoaded: true,
                privacyPolicyFormTopics: {
                    ...state.privacyPolicyFormTopics,
                    ...action.formTopics,
                }
            }
        case 'setNewSection':
            return {
                ...state,
                privacyPolicySectionsLoaded: false,
                unsavedPrivacyPolicySections: {
                    ...state.unsavedPrivacyPolicySections,
                    [action.hierarchicalClassification]: {
                        ...state.unsavedPrivacyPolicySections[action.hierarchicalClassification],
                        [action.hierarchicalSubClassification]: {
                            ...action.section,
                        },
                    },
                },
            }
        case 'deleteSection':
            return {
                ...state,
                privacyPolicySectionsLoaded: action.hierarchicalClassification,
            }
        case 'setPrivacyPolicySectionsLoaded':
            return {
                ...state,
                privacyPolicySectionsLoaded: action.value,
            }
        case 'setPrivacyPolicyFormTopicsLoaded':
            return {
                ...state,
                privacyPolicyFormTopicsLoaded: action.value,
            }
        case 'changePrivacyPolicySections':
            return {
                ...state,
                unsavedPrivacyPolicySections: {
                    ...state.unsavedPrivacyPolicySections,
                    [action.srcHierarchicalClassification]: action.destSection,
                    [action.destHierarchicalClassification]: action.srcSection,
                },
            }
        case 'setIsPrivacyPolicySaved':
            return {
                ...state,
                isPrivacyPolicySaved: action.value,
            }
        case 'setResponse':
            return {
                ...state,
                privacyPolicySectionsResponse: action.response,
            }
        case 'cleanup':
            return {
                ...initialPrivacyPolicySections(),
            }
        default:
            return {
                ...state,
                privacyPolicySectionsLoaded: true,
            };
    }
};
