// @flow

import React from "react";

export default function MarkdownLegend() {
    return (
        <>
            <span className="txt-bigger">Markdown Cheat Sheet</span>
            <div>
                <span className="txt-bold">Headers</span>
                <div className="txt">
                    <p># H1 <br />
                        ## H2 <br />
                        ### H3</p>
                </div>
                <span className="txt-bold">Lists</span>
                <div className="txt">
                    <p>1. First ordered list item<br />
                        2. Another item<br />
                        3. And another item</p>
                </div>
                <div className="txt">
                    <p>* First unordered list item<br />
                        * Another item<br />
                        * And another item</p>
                </div>
                <span className="txt-bold">Links</span>
                <div className="txt">
                    <p>&#91;Example-Link&#93;&#40;https://example.com&#41;</p>
                </div>
                <span className="txt-bold">Textfeld</span>
                <div className="txt">
                    <p>Zu bearbeitende Textabschnitte müssen mit &#123;&#123; &#125;&#125; umschlossen werden.</p>
                </div>
            </div>
        </>
    );
}
