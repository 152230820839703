// @flow

import React, {useEffect, useReducer} from "react";
import {Link} from "react-router-dom";
import Spinner from "../scss/img/spinner.gif";
import {loadProjectsApiCall} from "../Api/ProjectApi";
import {initialProjects, projectOverviewReducer} from "./reducer/projectReducer";
import {formatDateStringToUTC} from "./helper/dateFormatting";

export default function ProjectOverview(): ?$JSXIntrinsics<HTMLTableElement> {
    const [state, dispatch] = useReducer(projectOverviewReducer, initialProjects());

    useEffect(() => {
        if (!state.projectsLoaded) {
            loadProjects();
        }

        return () => {
            dispatch({});
        };
        // eslint-disable-next-line
    }, [state.projectsLoaded]);

    async function loadProjects(): void {
        const response = await loadProjectsApiCall();

        dispatch({
            type: 'setProjects',
            status: response.status,
            projects: response.projects,
        });
    }

    /** *** Hodor *** **/
    if (!state.projectsLoaded) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return (
        <div className="content__inner">
            <h1>Projekte</h1>
            <table className="table table__projects">
                <thead>
                <tr>
                    <th>Projekt (Domain)</th>
                    <th>DSE Stand</th>
                    <th>Projekttyp</th>
                    <th>Git</th>
                    <th>Firma</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                <TableContent projects={state.projects} />
                </tbody>
            </table>
            <Link className="btn__add" to={`/project/form`}>Neues Projekt</Link>
        </div>
    );
};

type TableContentProps = {
    projects: Project[],
};

function TableContent({projects,}: TableContentProps): ReactDOM$HTMLElementJSXIntrinsic<ProjectRecord>[] {
    return(
        projects.map((project: Project) => (
            <ProjectRecord
                key={project.projectId}
                project={project}
            />
        ))
    );
}

type ProjectRecordProps = {
    project: Project,
};

function ProjectRecord({project,}: ProjectRecordProps): $JSXIntrinsics<HTMLTableRowElement> {
    return(
            <tr key={project.projectId}>
                <td>{project.domain}</td>
                <td>{formatDateStringToUTC(new Date(project.lastPrivacyPolicyUpdateDate))}</td>
                <td>{project.projectType}</td>
                <td>{project.gitRepository}</td>
                <td>{project.companyName}</td>
                <td className="td__edit">
                    <Link className="btn__edit" to={`/project/form/${project.projectId}`}><span>edit</span></Link>
                </td>
            </tr>
    );
}
