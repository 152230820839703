// @flow

import React from "react";
import FormTopicDropDown from "./FormTopicDropDown";

declare type PrivacyPolicySubSectionProps = {
    privacyPolicySections: {},
    formTopics: { [key: string]: string },
    unsavedPrivacyPolicySections: {[key: number]: PrivacyPolicySection[]},
    savedPrivacyPolicySections: {[key: number]: PrivacyPolicySection[]},
    isPrivacyPolicySaved: boolean,
    changePrivacyPolicySavedState: Function,
    changeValue: Function,
    createNewSubSection: Function,
    deleteSection: Function,
    dispatch: Function,
}

export default function PrivacyPolicySubSectionRecords({
    privacyPolicySections,
    formTopics,
    unsavedPrivacyPolicySections,
    savedPrivacyPolicySections,
    isPrivacyPolicySaved,
    changePrivacyPolicySavedState,
    changeValue,
    trimValue,
    createNewSubSection,
    deleteSection,
    dispatch,
}: PrivacyPolicySubSectionProps) {
    return (
        <div className="edit__dse-section-wrapper">
            {Object.keys(privacyPolicySections).map((index, key) => {
                return (
                    <div key={key} className="edit__dse-section">
                        <div className="item-del">
                            <button className="btn__del"
                                    onClick={deleteSection.bind(this,
                                        privacyPolicySections[index].hierarchicalClassification,
                                        privacyPolicySections[index].hierarchicalSubClassification,
                                        privacyPolicySections[index].privacyPolicySectionId,
                                        unsavedPrivacyPolicySections,
                                        savedPrivacyPolicySections,
                                        dispatch,
                                        isPrivacyPolicySaved,
                                        changePrivacyPolicySavedState,
                                    )}
                            >
                            </button>
                        </div>
                        <div className="dse__section-counter">
                            {
                                privacyPolicySections[index].hierarchicalSubClassification === 0
                                    ? parseInt(privacyPolicySections[index].hierarchicalClassification, 10) + 1
                                    : null
                            }
                        </div>
                        <div className="item-100-x">
                            <div className="dse__section-checkbox">
                                <input
                                    name="isOptional"
                                    id={privacyPolicySections[index].privacyPolicySectionId}
                                    checked={privacyPolicySections[index].isOptional}
                                    type="checkbox"
                                    onChange={changeValue.bind(
                                        this,
                                        privacyPolicySections[index].hierarchicalClassification,
                                        privacyPolicySections[index].hierarchicalSubClassification,
                                    )}
                                />

                                <label className="checkbox"
                                       htmlFor={privacyPolicySections[index].privacyPolicySectionId}>optional</label>
                            </div>
                            <div className="dse__section-select">
                                {
                                    privacyPolicySections[index].hierarchicalSubClassification !== 0
                                        ? null
                                        : <FormTopicDropDown
                                            formTopics={formTopics}
                                            selectedTopic={privacyPolicySections[index].formTopic}
                                            hierarchicalClassification={privacyPolicySections[index].hierarchicalClassification}
                                            hierarchicalSubClassification={privacyPolicySections[index].hierarchicalSubClassification}
                                            changeValue={changeValue}
                                        />
                                }
                            </div>

                            <div className="dse__section-input">
                                <input
                                    name="sectionTitle"
                                    placeholder="Titel des Bausteins"
                                    value={privacyPolicySections[index].sectionTitle}
                                    onChange={changeValue.bind(
                                        this,
                                        privacyPolicySections[index].hierarchicalClassification,
                                        privacyPolicySections[index].hierarchicalSubClassification,
                                    )}
                                    onBlur={trimValue.bind(
                                        this,
                                        privacyPolicySections[index].hierarchicalClassification,
                                        privacyPolicySections[index].hierarchicalSubClassification,
                                    )}
                                />
                            </div>
                            <div className="dse__section-textarea">
                                <textarea
                                    name="sectionContent"
                                    placeholder="Inhalt des Bausteins"
                                    value={privacyPolicySections[index].sectionContent}
                                    onChange={changeValue.bind(
                                        this,
                                        privacyPolicySections[index].hierarchicalClassification,
                                        privacyPolicySections[index].hierarchicalSubClassification,
                                    )}
                                    onBlur={trimValue.bind(
                                        this,
                                        privacyPolicySections[index].hierarchicalClassification,
                                        privacyPolicySections[index].hierarchicalSubClassification,
                                    )}
                                    rows="4"
                                    cols="50"
                                />
                            </div>
                        </div>
                        {
                            (Object.keys(privacyPolicySections).length) === (key + 1) &&
                            <div className="dse__section-buttonbox">
                                <button className="btn__add-dse-a"
                                        value={privacyPolicySections[index].hierarchicalClassification}
                                        onClick={createNewSubSection.bind(
                                            this,
                                            Object.keys(privacyPolicySections).length,
                                            true,
                                            unsavedPrivacyPolicySections,
                                            savedPrivacyPolicySections,
                                            isPrivacyPolicySaved,
                                            dispatch,
                                            changePrivacyPolicySavedState,
                                            privacyPolicySections[index].formTopic,
                                        )}
                                >
                                    Neuer Unterabschnitt
                                </button>
                                {
                                    (Object.keys(privacyPolicySections).length) === (key + 1) && privacyPolicySections[index].isSubSection ?
                                        <button className="btn__con-dse"
                                            value={privacyPolicySections[index].hierarchicalClassification}
                                            onClick={createNewSubSection.bind(
                                                this,
                                                Object.keys(privacyPolicySections).length,
                                                false,
                                                unsavedPrivacyPolicySections,
                                                savedPrivacyPolicySections,
                                                isPrivacyPolicySaved,
                                                dispatch,
                                                changePrivacyPolicySavedState,
                                                privacyPolicySections[index].formTopic,
                                            )}
                                        >
                                            Hauptabschnitt fortführen
                                        </button> :
                                        null
                                }
                            </div>
                        }
                    </div>
                );
            },)
            }
        </div>
    );
}