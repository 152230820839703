// @flow

export type AboutMeResponse = {
    role: string,
    identifier: string,
    errors: boolean,
    errorMessages: [],
    status: ?number,
}

export async function aboutMeApiCall(): Promise<AboutMeResponse> {
    let response;

    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
            credentials: 'include',
            method: 'GET',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const responseData = await response.json();

        return {
            identifier: responseData.body.identifier,
            role: responseData.body.role,
            errors: responseData.errors,
            errorMessages: responseData.errorMessages,
            status: response.status,
        }
    } catch (e) {
        return {
            role: '',
            errors: true,
            errorMessages: e.errorMessages,
            status: response ? response.status : null,
        }
    }
}
