// @flow

import React, {useEffect, useReducer, useState} from "react";
import {Redirect} from "react-router-dom";
import NotAuthorizedError from "../../Authorization/NotAuthorizedError";
import Spinner from '../../scss/img/spinner.gif';
import DynamicFormBody from "./DynamicFormBody";
import StaticFormBody from "./StaticFormBody";
import {FormHeader} from "./FormDefaultElements";
import hasUnsavedPrivacyPolicySections from "../helper/privacyPolicyChangeValidation";
import buildProjectCommands from "../helper/projectCommandFactory";
import {
    initialProject,
    initialProjectHtmlFormElements,
    projectHtmlFormElementsReducer,
    projectReducer
} from "../reducer/projectReducer";
import {commandActionApi} from "../../Api/CommandApi";
import {loadCustomers, loadPrivacyPolicySections, loadProjectTypes} from "../helper/backendSynchronisation";
import {loadProjectByIdApiCall, loadProjectsPrivacyPolicyAsHtmlApiCall} from "../../Api/ProjectApi";
import {
    getDefaultFadeInSections,
    getProjectsDefaultPrivacyPolicySubSections,
    getProjectsFadeInSubSections,
    getProjectsSelectedSubSections
} from "../helper/formInitialisation";
import {
    handleMainSectionAdded,
    handleMainSectionRemoved,
    handleSubSectionChanged
} from "../helper/changePrivacyPolicySection";

type Props = {
    match: {
        params: {
            projectId: string,
        },
    },
};

export default function GeneralProjectForm({match}: Props): ?$JSXIntrinsics<HTMLFormElement> {
    const [project, dispatchProject] = useReducer(projectReducer, initialProject());
    const [htmlFormElements, dispatchFormElements] = useReducer(projectHtmlFormElementsReducer, initialProjectHtmlFormElements());
    // TODO: Einen useReducer für das Error-Handling implementieren und den useState entfernen. Komplett!!!
    const [domainError, setDomainError] = useState<string>('');

    /**
     * @summary Laden der Values für die Komponenten des Formulars
     * Wird einmalig beim Aufruf der Komponente ausgeführt
     */
    useEffect(() => {
        loadCustomers(dispatchFormElements);
        loadProjectTypes(dispatchFormElements);
        loadPrivacyPolicySections(dispatchFormElements);

        return () => {
            dispatchFormElements({ type: 'cleanup', });
        };
        // eslint-disable-next-line
    }, []);

    /**
     * @summary Neues Projekt initialisieren oder bereits vorhandenes Projekt laden
     * Wird ausgeführt sobald alle Values des Formulars geladen sind
     * Setzen der Default-Bausteine für die DSE eines neu initialisierten Projektes
     * oder
     * Übertragen der DSE eines geladenen Projektes in den State des Formulars
     */
    useEffect(() => {
        function initialiseProject(): void {
            let defaultSubSections = getProjectsDefaultPrivacyPolicySubSections({...htmlFormElements.privacyPolicySections});

            dispatchProject({
                type: 'setNewProject',
                defaultMainSections: htmlFormElements.privacyPolicySections.defaultSections.mainSections,
                defaultCustomRequiredMainSections: htmlFormElements.privacyPolicySections.defaultCustomRequiredSections.mainSections,
                defaultSubSections: defaultSubSections,
            });
        }

        async function loadProject(projectId: string): void {
            const response = await loadProjectByIdApiCall(projectId);

            dispatchProject({
                type: 'setKnownProject',
                status: response.status,
                project: response.project,
            });
        }

        if (
            htmlFormElements.customerId.options
            && htmlFormElements.projectTypeId.radioButtons
            && htmlFormElements.privacyPolicySections
        ) {
            match.params.projectId ? loadProject(match.params.projectId) : initialiseProject();
        }

        return () => {
            dispatchProject({ type: 'cleanup' });
        };
    }, [
        match.params.projectId,
        htmlFormElements.customerId.options,
        htmlFormElements.projectTypeId.radioButtons,
        htmlFormElements.privacyPolicySections,
    ]);

    /**
     * @summary Formular für ein neu initialisiertes Projekt einrichten
     * Setzen einzublendender Checkboxen in 'htmlFormElements.privacyPolicyFadeInSections'
     */
    useEffect(() => {
        if (project.newProject) {
            // Alle Default-Hauptabschnitte
            // Alle optionalen Unterabschnitte, die einem Default-Hauptabschnitt angehören
            const fadeInDefaultSections = getDefaultFadeInSections({...htmlFormElements.privacyPolicySections});

            dispatchFormElements({
                type: 'updatePrivacyPolicyFadeInSections',
                fadeInSections: fadeInDefaultSections,
            });
        }
    }, [project.newProject, htmlFormElements.privacyPolicySections]);

    /**
     * @summary Formular für ein geladenes Projekt einrichten
     * Setzen einzublendender Checkboxen in 'htmlFormElements.privacyPolicyFadeInSections'
     */
    useEffect(() => {
        if (project.knownProjectFetched) {
            // Alle Default-Hauptabschnitte
            // Alle optionalen Unterabschnitte, die einem Default-Hauptabschnitt angehören
            let fadeInProjectSections = getDefaultFadeInSections({...htmlFormElements.privacyPolicySections});

            // Alle optionalen Unterabschnitte, die in der DSE des Projektes enthalten sind (checked = true)
            fadeInProjectSections = getProjectsSelectedSubSections(
                {...project.savedProject.privacyPolicySections},
                fadeInProjectSections,
            );

            // Hinzufügen der Unterabschnitte, deren Hauptabschnitte in der DSE des Projektes liegen (checked = false)
            fadeInProjectSections = getProjectsFadeInSubSections(
                {...htmlFormElements.privacyPolicySections},
                {...project.savedProject.privacyPolicySections},
                fadeInProjectSections,
            );

            dispatchFormElements({
                type: 'updatePrivacyPolicyFadeInSections',
                fadeInSections: fadeInProjectSections,
            });
        }
    }, [project.knownProjectFetched, htmlFormElements.privacyPolicySections]);

    const doSubmit = async (event: SyntheticEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const commands = buildProjectCommands(project);

        if (!commands || commands.length === 0) {
            return;
        }

        const response = await commandActionApi(commands, '/project/action');

        if (response.status === 400) {
            // TODO: Error-Handler erstellen. Error der Response optimieren, aktuell nur unter dem key 'commandName'
            setDomainError('Fehler: Die Domain existiert bereits.');

            return;
        }

        if (Object.keys(response.response).includes('createProject')) {
            dispatchProject({
                type: 'setNewProjectCreated',
                status: response.status,
                projectId: response.response.createProject.body.projectId,
            });

            dispatchFormElements({ type: 'cleanup' });

            return;
        }

        dispatchProject({
            type: 'transferUpdatedProjectValues',
            status: response.status,
        });
    };

    function changeStaticFormElementValue(event: SyntheticEvent<HTMLFormElement>): void {
        setDomainError('');

        if (event.target.maxLength !== -1 && event.target.value.length >= event.target.maxLength) {
            return;
        }

        dispatchProject({
            type: 'changeProjectValue',
            prop: event.target.name,
            value: event.target.value,
        });
    }

    function changeSectionCheckbox(event: SyntheticEvent<HTMLFormElement>): void {
        setDomainError('');

        if (event.target.maxLength !== -1) {
            return;
        }

        /**
         * 1. Ausgewählter DSE-Baustein ist ein Unterabschnitt
         * 1.1 Unterabschnitt löschen oder 1.2 Unterabschnitt hinzufügen
         */
        if (event.target.slot === 'subSection') {
            const projectAction = handleSubSectionChanged(
                event.target.checked,
                event.target.id,
                event.target.name,
                {...project.unsavedProject.privacyPolicySections},
                {...htmlFormElements.privacyPolicySections},
            );

            dispatchProject(projectAction);

            return;
        }

        /**
         * 2. Ausgewählter DSE-Baustein ist ein Hauptabschnitt
         * 2.1 Den ausgewählten Hauptabschnitt ('optional' oder 'customRequired') 'selectedSection' zuweisen
         */
        let selectedSection: PrivacyPolicySection = {};

        if (event.target.name === 'optional') {
            selectedSection = {...htmlFormElements.privacyPolicySections.optionalSections.mainSections[event.target.id]};
        }

        if (event.target.name === 'customRequired') {
            selectedSection = {...htmlFormElements.privacyPolicySections.optionalCustomRequiredSections.mainSections[event.target.id]};
        }

        /** 2.2 Hauptabschnitt löschen */
        if (!event.target.checked) {
            const reducerActions = handleMainSectionRemoved(
                selectedSection,
                {...project.unsavedProject.privacyPolicySections},
                {...htmlFormElements.privacyPolicyFadeInSections},
                {...htmlFormElements.privacyPolicySections},
            );

            // Updaten der einzublendenden Checkboxen und der DSE-Bausteine des Projektes
            dispatchFormElements(reducerActions.formElementsAction);
            dispatchProject(reducerActions.projectAction);

            return;
        }

        /** 2.3 Hauptabschnitt hinzufügen */
        const reducerActions = handleMainSectionAdded(
            selectedSection,
            {...project.unsavedProject.privacyPolicySections},
            {...htmlFormElements.privacyPolicyFadeInSections},
            {...htmlFormElements.privacyPolicySections},
        );

        // Updaten der einzublendenden Checkboxen und der DSE-Bausteine des Projektes
        dispatchFormElements(reducerActions.formElementsAction);
        dispatchProject(reducerActions.projectAction);
    }

    function changeCustomRequiredSectionContent(event: SyntheticEvent<HTMLFormElement>): void {
        setDomainError('');

        if (event.target.maxLength !== -1 && event.target.value.length >= event.target.maxLength) {
            return;
        }

        /**
         * @summary RegEx mit der Option 'single line' (s) und ohne die Option 'global' (g).
         * Vorerst kann nur ein Match gehandelt werden kann.
         * Umstellen auf 'gs', sobald mehrere Textabschnitte eines DSE-Bausteins bearbeitet werden können sollen.
         * 1. /{{2}.*}{2}/s         => {{{{¡Hellos mundo!}}}}  ––– -matches- ––>  {{{{¡Hellos mundo!}}}}
         * 2. /{{2}[^{]*?}{2}/s     => {{{{¡Hellos mundo!}}}}  ––– -matches- ––>  {{¡Hellos mundo!}}
         */
        // TODO: kann besser
        const customizedContent = project.unsavedProject.privacyPolicySections[event.target.id].sectionContent.replace(
            /{{2}[^{]*?}{2}/s,
            `{{${event.target.value}}}`,
            );

        dispatchProject({
            type: 'changePrivacyPolicySectionContent',
            sectionId: event.target.id,
            sectionContent: customizedContent,
        });
    }

    const exportHtmlFile = async (): void => {
        let isExportValid = validateHtmlExportRequest();

        if (!isExportValid) {
            return;
        }

        const response = await loadProjectsPrivacyPolicyAsHtmlApiCall(project.savedProject.projectId);

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        if (response.status === 201) {
            let privacyPolicyCustomer: null|string = null;

            // Setzen des Namens des Inhabers des Projektes
            for (const [index: string, customer: {name: string, value: string}] of Object.entries(htmlFormElements.customerId.options)) {
                if (customer.value === project.savedProject.customerId) {
                    privacyPolicyCustomer = customer.name;
                }
            }

            function download(blob, filename) {
                // Erstellen der Download-URL und des anchor-Elements <a>
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                // Definieren der Attribute des <a> Elements, klicken von <a> und die zugewiesene URL wieder entfernen
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }

            response.htmlFile.then(blob => download(blob, `${privacyPolicyCustomer}_${match.params.projectId}`));
        }
    }

    function validateHtmlExportRequest(): boolean {
        if (!project.savedProject.customerId) {
            return false;
        }

        let hasUnsavedValues = hasUnsavedPrivacyPolicySections(
            project.savedProject.privacyPolicySections,
            project.unsavedProject.privacyPolicySections
        );

        return !hasUnsavedValues;
    }

    if (project.newProjectId) {
        return <Redirect to={`/project/form/${project.newProjectId}`} />;
    }

    /** *** Hodor *** **/
    if (!htmlFormElements.privacyPolicyFadeInSections) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return (
        <form className="form__add-project" key="projectForm" onSubmit={doSubmit}>
            <FormHeader newProject={project.newProject} />
            <StaticFormBody
                domainError={domainError}
                formElements={{...htmlFormElements}}
                unsavedProject={{...project.unsavedProject}}
                changeValue={changeStaticFormElementValue}
            />
            <DynamicFormBody
                fadeInSections={{...htmlFormElements.privacyPolicyFadeInSections}}
                selectedPrivacyPolicySections={{...project.unsavedProject.privacyPolicySections}}
                changeSectionCheckbox={changeSectionCheckbox}
                changeCustomRequiredSectionContent={changeCustomRequiredSectionContent}
                exportHtmlFile={exportHtmlFile}
                disableExport={[
                    project.newProject
                        ? true
                        : hasUnsavedPrivacyPolicySections(
                            project.savedProject.privacyPolicySections,
                            project.unsavedProject.privacyPolicySections,
                        ),
                    project.savedProject ? !project.savedProject.customerId : true,
                ]}
                newProject={project.newProject}
            />
        </form>
    );
};
