// @flow

export async function loadCustomersApiCall(): Promise<CustomersResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/customer/all`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            customers: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        }
    } catch (e) {
        return {
            customers: [],
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function loadCustomerApiCall(id: string): Promise<CustomerResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/customer/id/${id}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            customer: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        }
    } catch (e) {
        return {
            customer: null,
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        }
    }
}
