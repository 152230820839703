// @flow

import {loadCustomersApiCall} from "../../Api/CustomerApi";
import {loadOptionValuesApiCall} from "../../Api/HtmlOptionValueApi";
import {loadPrivacyPolicySectionsApiCall} from "../../Api/PrivacyPolicySectionApi";

export async function loadCustomers(dispatchFormElements: Function): void {
    const response = await loadCustomersApiCall();

    if (response.status === 200) {
        let options = [];

        response.customers.map((customer: Customer) => (
            options.push({
                name: customer.companyName,
                value: customer.customerId,
            })
        ));

        dispatchFormElements({
            type: 'setCustomerOptions',
            options: options,
        });
    }
}

export async function loadProjectTypes(dispatchFormElements: Function): void {
    const response = await loadOptionValuesApiCall('project-types');

    if (response.status === 200) {
        let radioButtons = [];

        response.values.map((projectType: ProjectType) => (
            radioButtons.push({
                label: projectType.name,
                value: projectType.projectTypeId,
            })
        ));

        dispatchFormElements({
            type: 'setProjectTypeRadioButtons',
            radioButtons: radioButtons,
        });
    }
}

export async function loadPrivacyPolicySections(dispatchFormElements: Function): void {
    const response = await loadPrivacyPolicySectionsApiCall();

    if (response.status === 200) {
        dispatchFormElements({
            type: 'setPrivacyPolicySections',
            sections: response.sections,
        });
    }
}
