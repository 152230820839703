// @flow

import React, {useEffect, useReducer} from "react";
import {Redirect} from "react-router-dom";
import Spinner from "../scss/img/spinner.gif";
import {customerFormReducer, initialCustomer} from "./reducer/customerReducer";
import {loadCustomerApiCall} from "../Api/CustomerApi";
import {loadOptionValuesApiCall} from "../Api/HtmlOptionValueApi";
import buildCustomerCommands from "./customerCommandFactory";
import {commandActionApi} from "../Api/CommandApi";

type Props = {
    match: {
        params: {
            customerId: string,
        },
    },
}

export default function CustomerForm({match,}: Props): ?$JSXIntrinsics<HTMLFormElement> {
    const [state, dispatch] = useReducer(customerFormReducer, initialCustomer());

    useEffect(() => {
        if (!state.legalStructureIdsLoaded) {
            loadlegalStructureIds();
        }

        return () => {
            dispatch({ type: 'cleanup', });
        }
        // eslint-disable-next-line
    }, []);

    async function loadlegalStructureIds(): void {
        const response = await loadOptionValuesApiCall('legal-structures');

        dispatch({
            type: 'setlegalStructureIds',
            status: response.status,
            legalStructureIds: response.values,
        });
    }

    useEffect(() => {
        if (match.params.customerId) {
            loadCustomer();
        }

        return () => {
            dispatch({ type: 'cleanup', });
        }
        // eslint-disable-next-line
    }, [match.params.customerId]);

    async function loadCustomer(): void {
        const response = await loadCustomerApiCall(match.params.customerId);

        dispatch({
            type: 'setCustomerValues',
            status: response.status,
            receivedValues: response.customer,
        });
    }

    const doSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const commands = buildCustomerCommands(state.savedCustomer, state.unsavedCustomer);

        if (commands.length === 0) return;

        const response = await commandActionApi(commands, '/customer/action');

        dispatch({
            type: 'setResponse',
            customerResponse: response.response,
        });
    }

    function changeValue(event: SyntheticInputEvent<HTMLInputElement>): void {
        if (event.target.value.length > 255) return;

        dispatch({
            type: 'setValue',
            property: event.target.name,
            value: event.target.value,
        });
    }

    function changeCustomerAddressValue(event: SyntheticInputEvent<HTMLInputElement>): void {
        if (event.target.value.length > 255) return;

        dispatch({
            type: 'setCustomerAddressValue',
            property: event.target.name,
            value: event.target.value,
        });
    }

    function createErrorMessage(key: string) {
        return (
            state.customerResponse[key].errorMessage === '' ? null : state.customerResponse[key].errorMessage
        );
    }

    if (Object.keys(state.customerResponse.createCustomer.body).length > 0 ) {
        return <Redirect to={`/customer/form/${state.customerResponse.createCustomer.body.customerId}`}/>
    }

    if (match.params.customerId && !state.customerLoaded) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return(
        <>
            <FormHeader customerId={match.params.customerId} />
            <form className="form__add-customer" onSubmit={doSubmit}>
                {Object.keys(state.formTextLabels).map((key) => {
                    if (Object.keys(state.customerAddress).includes(key)) {
                        return (
                            <label key={key}>
                                {state.formTextLabels[key].label}
                                <input
                                    key={key}
                                    name={key}
                                    type="text"
                                    maxLength="255"
                                    value={state.unsavedCustomer.customerAddress[key]}
                                    onChange={changeCustomerAddressValue}
                                />
                                <div>
                                    {typeof state.customerResponse[key] !== 'undefined' ? createErrorMessage(key) : null}
                                </div>
                            </label>
                        );
                    }

                    return (
                        <label key={key}>
                            {state.formTextLabels[key].label}
                            <input
                                key={key}
                                name={key}
                                type="text"
                                maxLength="255"
                                value={state.unsavedCustomer[key]}
                                onChange={changeValue}
                            />
                            <div>
                                {typeof state.customerResponse[key] !== 'undefined' ? createErrorMessage(key) : null}
                            </div>
                        </label>
                    );
                },)}
                <label>
                    Rechtsform:
                    <select
                        name="legalStructureId"
                        value={state.unsavedCustomer.legalStructureId}
                        onChange={changeValue}>
                        <option>Bitte auswählen</option>
                        {state.legalStructureIds.map((
                            legalStructureId: {"id": string, "fullName": string, "shortName": string},
                            key
                        ) => (
                            <option key={key} value={legalStructureId.id}>{legalStructureId.shortName}</option>
                        ))}
                    </select>
                </label>
                <button className="btn__save">Speichern</button>
            </form>
        </>
    );
}

type FormHeaderProps = {
    customerId: 'undefined'|string,
};

function FormHeader({customerId}: FormHeaderProps): $JSXIntrinsics<HTMLHeadingElement> {
    return typeof customerId !== 'undefined' ? <h1>Firma bearbeiten</h1> : <h1>Firma anlegen</h1>;
}
