// @flow

export async function loadOptionValuesApiCall(select: string): Promise<ValueListResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/get/${select}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            values: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            values: [],
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
