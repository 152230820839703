// @flow

import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import {loginCall} from "../Api/AuthenticationApi";
import {aboutMeApiCall} from "../Api/AuthorizationApi";
import type {AuthenticationResponse} from "../Api/AuthenticationApi";
import {ReactComponent as HeaderImage} from '../scss/img/header_logo.svg';
import {default as Image} from '../scss/img/header_agentur_small.jpg';

export default function Login() {
    const [response, setResponse] = useState<AuthenticationResponse>({errors: true, errorMessages: [], status: null,});
    const [loginParams, setLoginParams] = useState<{ email: string, password: string }>({email: '', password: ''});
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        proofLoginState();

        async function proofLoginState(): void {
            await doProofLoginState();
        }
    }, []);

    const doProofLoginState = async (): void => {
        const response = await aboutMeApiCall();
        setIsLoggedIn(() => {return response.status === 200});
    };

    if (isLoggedIn) {
        return (
            <Redirect to="/project/overview"/>
        );
    }

    const doLogin = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        setResponse(await loginCall(loginParams));
    };

    const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
        setLoginParams({
            ...loginParams,
            [event.target.name]: event.target.value,
        });
    };

    if (response.errors) {
        // TODO: Errorhandling implementieren
    }

    if (response.status === 200 && !response.errors) {
        return (
            <Redirect to="/project/overview"/>
        );
    }

    return (
        <div className="login-window">
            <header>
                <div className="header__logo">
                    <HeaderImage />
                </div>
            </header>
            <div className="container flex flex-between">
                <div className="item-66">
                    <div className="image">
                        <img src={Image} title="synatix privacy manager" alt="synatix privacy manager"/>
                    </div>
                </div>
                <div className="item-26">
                    <form className="login_form">
                        <h1>Login</h1>
                        <div className="login_icons">
                            <label>Benutzername:</label>
                            <i className="user" />
                            <input
                                className="login_input-field"
                                id="email"
                                type="text"
                                name="email"
                                placeholder="E-Mail-Adresse"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="login_icons">
                            <label>Passwort:</label>
                            <i className="passwd" />
                            <input
                                className="login_input-field"
                                id="password"
                                type="password"
                                name="password"
                                placeholder="Passwort"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="login_error">
                            <span>Schreib das richtig! Oder frage deinen Passwort-Manager...</span>
                        </div>
                        <div className="position-rel">
                            <button className="login_submit" type="submit" onClick={doLogin}>Anmelden</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
