// @flow

import React, {useState} from "react";
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown'

declare type PrivacyPolicySectionMardownModalProps = {
    privacyPolicy: {},
}

export default function PrivacyPolicyPreview({privacyPolicy}: PrivacyPolicySectionMardownModalProps) {
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function closeModal(){
        setIsOpen(false);
    }

    return (
        <div>
            <button className="btn__show" onClick={openModal}>Vorschau</button>
            <Modal
                appElement={document.getElementById('main')}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <button onClick={closeModal}>Schließen</button>
                {Object.keys(privacyPolicy).map((classification) => {
                    return Object.keys(privacyPolicy[classification]).map((
                        subClassification,
                        key,
                    ) => {
                        return (
                            <ReactMarkdown
                                key={key}
                                children={privacyPolicy[classification][subClassification].sectionContent}
                            />
                        )
                    })
                })}
            </Modal>
        </div>
    );
}
