// @flow

export const formatDateStringToUTC = (d: Date): string => {
    const day = d.getUTCDate() >= 10 ? d.getUTCDate().toString() : `0${d.getUTCDate().toString()}`;
    const m = (d.getUTCMonth() + 1) >= 10 ? (d.getUTCMonth() + 1).toString() : `0${(d.getUTCMonth() + 1).toString()}`;
    const y = d.getUTCFullYear().toString();
    const h = d.getUTCHours() >= 10 ? d.getUTCHours().toString() : `0${d.getUTCHours().toString()}`;
    const min = d.getUTCMinutes() >= 10 ? d.getUTCMinutes().toString() : `0${d.getUTCMinutes().toString()}`;
    const s = d.getUTCSeconds() >= 10 ? d.getUTCSeconds().toString() : `0${d.getUTCSeconds().toString()}`;

    return `${day}.${m}.${y} – ${h}:${min}:${s} Uhr`;
};
