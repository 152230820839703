// @flow

export default function hasUnsavedPrivacyPolicySections(
    savedSections: PrivacyPolicySection[],
    unsavedSections: PrivacyPolicySection[]
): boolean {
    // Wenn sich die Anzahl der DSE-Bausteine des 'savedProject' und 'unsavedProject' unterscheidet, wird true
    // zurückgegeben und alle weiteren Prüfungen werden übersprungen.
    if (Object.keys(unsavedSections).length !== Object.keys(savedSections).length) {
        return true;
    }

    // Wenn die Anzahl der DSE-Bausteine des 'savedProject' und 'unsavedProject' gleich sind, werden die ID's der
    // enthaltenen DSE-Bausteine abgeglichen. Unterscheiden sie sich, wird true zurückgegeben.
    let hasAnyPrivacyPolicySectionIdReplaced = Object.keys(unsavedSections).map((sectionId: string) => {
        return !Object.keys(savedSections).includes(sectionId);
    });

    if (hasAnyPrivacyPolicySectionIdReplaced.includes(true)) {
        return true;
    }

    // Wenn die Anzahl und die ID's der DSE-Bausteine gleich sind, wird der Inhalt und die Version jedes
    // DSE-Bausteins verglichen. Sollte ein Unterschied vorliegen, wird true zurückgegeben.
    let hasAnySectionContentChanged = Object.keys(unsavedSections).map((sectionId: string) => {
        return unsavedSections[sectionId].sectionContent !== savedSections[sectionId].sectionContent;
    });

    let hasAnySectionVersionChanged = Object.keys(unsavedSections).map((sectionId: string) => {
        return unsavedSections[sectionId].version !== savedSections[sectionId].version;
    });

    // Liegt kein Unterschied in beiden States vor, wird false zurückgegeben.
    return hasAnySectionContentChanged.includes(true) || hasAnySectionVersionChanged.includes(true);
};
