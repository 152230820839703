// @flow

import React, {useEffect, useReducer} from "react";
import {Link} from "react-router-dom";
import Spinner from "../scss/img/spinner.gif";
import {customerOverviewReducer, initialCustomers} from "./reducer/customerReducer";
import {loadCustomersApiCall} from "../Api/CustomerApi";
import {formatDateStringToUTC} from "../Project/helper/dateFormatting";

export default function CustomerOverview(): ?$JSXIntrinsics<HTMLTableElement> {
    const [customers, dispatchCustomers] = useReducer(customerOverviewReducer, initialCustomers());

    useEffect(() => {
        async function loadCustomers(): void {
            const response = await loadCustomersApiCall();

            dispatchCustomers({
                type: 'setCustomers',
                status: response.status,
                customers: response.customers,
            });
        }

        if (!customers.areCustomersLoaded) {
            loadCustomers();
        }

        return() => {
            dispatchCustomers({});
        }
    }, [customers.areCustomersLoaded]);

    if (!customers.areCustomersLoaded) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return (
        <div className="content__inner">
            <h1>Firmen</h1>
            <table className="table table__customer">
                <thead>
                <tr>
                    <th>Firma</th>
                    <th>Geschäftsführer</th>
                    <th>Rechtsform</th>
                    <th>Telefon</th>
                    <th>E-Mail</th>
                    <th>E-Mail DS</th>
                    <th>Registriert am</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                <TableContent customers={customers.customers} />
                </tbody>
            </table>
            <Link className="btn__add" to={`/customer/form`}>Erstellen</Link>
        </div>
    );
}

type TableContentProps = {
    customers: Customer[],
};

function TableContent({customers}: TableContentProps): ReactDOM$HTMLElementJSXIntrinsic<CustomerRecord>[] {
    return(
        customers.map((customer: Customer) => (
            <CustomerRecord key={customer.customerId} customer={customer} />
        ))
    );
}

type CustomerRecordProps = {
    customer: Customer,
};

function CustomerRecord({customer}: CustomerRecordProps): $JSXIntrinsics<HTMLTableRowElement> {
    return(
        <tr>
            <td>{customer.companyName}</td>
            <td>{customer.managingDirector}</td>
            <td>{customer.legalStructure}</td>
            <td>{customer.phoneNumber}</td>
            <td>{customer.companyEmailAddress}</td>
            <td>{customer.dataProtectionOfficerEmailAddress}</td>
            <td>{formatDateStringToUTC(new Date(customer.registeredOn))}</td>
            <td className="td__edit">
                <Link className="btn__edit" to={`/customer/form/${customer.customerId}`}><span>edit</span></Link>
            </td>
        </tr>
    );
}
