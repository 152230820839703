// @flow

export async function loadPrivacyPolicySectionsApiCall(): Promise<PrivacyPolicySectionsResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/get/privacy-policy-sections`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            sections: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        };
    } catch (e) {
        return {
            sections: null,
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        };
    }
}

export async function loadAllPrivacyPolicySectionsApiCall(): Promise<AllPrivacyPolicySectionsResponse> {
    let response;
    try {
        response = await fetch(`${process.env.REACT_APP_API_URL}/privacy-policy-section/all`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            privacyPolicySections: data,
            errors: false,
            errorMessages: [],
            status: response.status,
        }
    } catch (e) {
        return {
            privacyPolicySections: [],
            errors: true,
            errorMessages: e.message,
            status: response ? response.status : null,
        }
    }
}
