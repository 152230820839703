// @flow

import React, {useEffect, useState} from "react";
import {Checkbox, DynamicText} from "./FormInputElements";
import {SubmitButton, HtmlExportButton} from "./FormDefaultElements";

type DynamicFormBodyProps = {
    fadeInSections: PrivacyPolicyFadeInSections,
    selectedPrivacyPolicySections: PrivacyPolicySection[],
    changeSectionCheckbox: Function,
    changeCustomRequiredSectionContent: Function,
    exportHtmlFile: Function,
    disableExport: boolean[],
    newProject: boolean,
};

export default function DynamicFormBody(
    {
        fadeInSections,
        selectedPrivacyPolicySections,
        changeSectionCheckbox,
        changeCustomRequiredSectionContent,
        exportHtmlFile,
        disableExport,
        newProject
    }: DynamicFormBodyProps
): $JSXIntrinsics<HTMLFormElement> {
    const [checkboxFormTopics, setCheckboxFormTopics] = useState<null|string[]>(null);
    const [textareaFormTopics, setTextareaFormTopics] = useState<null|string[]>(null);

    // Setzen der Überschriften aller auswählbaren Checkboxen für:
    // 1. Optionale Haupt- und Unterabschnitte
    // 2. Optionale und anzupassende Haupt- und Unterabschnitte
    // 3. Optionalen stand-alone-Unterabschnitte (stand-alone dadurch, dass die entsprechenden Hauptabschnitte per
    //    Default der DSE hinzugefügt werden. Die Checkboxen dieser optionalen Unterabschnitte müssen eingeblendet
    //    werden, auch ohne eingeblendete/gecheckte Hauptabschnitte)
    useEffect(() => {
        function setTopics(fadeInSections: PrivacyPolicyFadeInSections, setter: Function): void {
            // Wenn es keine DSE-Bausteine gibt, wird ein leeres Array für die Überschriften der Kategorie gesetzt
            if (!fadeInSections || Object.keys(fadeInSections).length === 0) {
                setter([]);
            }

            if (fadeInSections && Object.keys(fadeInSections).length > 0) {
                let formTopics = [];

                // In sectionChunk steht 'optionalMainSections', 'optionalSubSections',
                // 'optionalCustomRequiredMainSections' oder 'optionalCustomRequiredSubSections'
                for(const [sectionChunk: string, sections: PrivacyPolicySection[]] of Object.entries(fadeInSections)) {
                    for (const sectionId in sections) {
                        if (!formTopics.includes(fadeInSections[sectionChunk][sectionId].formTopic)) {
                            formTopics.push(fadeInSections[sectionChunk][sectionId].formTopic);
                        }
                    }
                }

                setter([...formTopics]);
            }
        }

        setTopics(fadeInSections, setCheckboxFormTopics);

        return(() => {
            setCheckboxFormTopics(null);
        });
        // eslint-disable-next-line
    }, [selectedPrivacyPolicySections]);

    // Setzen der Überschriften für die Tetxtfelder aller ausgewählten DSE-Bausteine, die angepasst werden müssen
    useEffect(() => {
        if (!selectedPrivacyPolicySections || Object.keys(selectedPrivacyPolicySections).length === 0) {
            setTextareaFormTopics([]);
        }

        if (selectedPrivacyPolicySections && Object.keys(selectedPrivacyPolicySections).length > 0) {
            let formTopics = [];

            for(const [sectionId: string, section: PrivacyPolicySection] of Object.entries(selectedPrivacyPolicySections)) {
                if (section.isCustomizable && !formTopics.includes(section.formTopic)) {
                    formTopics.push(section.formTopic);
                }
            }

            setTextareaFormTopics([...formTopics]);
        }

        return(() => {
            setTextareaFormTopics(null);
        });
        // eslint-disable-next-line
    }, [selectedPrivacyPolicySections]);

    /*** Hodor ***/
    if (!checkboxFormTopics || !textareaFormTopics) {
        return null;
    }

    return (
        <div className="form__add-project__three">
            <div className="item-33">
                <CheckBoxHeap
                    checkboxTopics={checkboxFormTopics}
                    sections={{...fadeInSections.optionalMainSections, ...fadeInSections.optionalSubSections}}
                    unsavedSections={selectedPrivacyPolicySections}
                    onChange={changeSectionCheckbox}
                />
            </div>
            <div className="item-33">
                <CheckBoxHeap
                    checkboxTopics={checkboxFormTopics}
                    sections={{
                        ...fadeInSections.optionalCustomRequiredMainSections,
                        ...fadeInSections.optionalCustomRequiredSubSections,
                    }}
                    unsavedSections={selectedPrivacyPolicySections}
                    onChange={changeSectionCheckbox}
                />
            </div>
            <div className="item-33">
                <span className="span__edit">Anpassen</span>
                <DynamicTextCols
                    sectionsFormTopics={textareaFormTopics}
                    sections={selectedPrivacyPolicySections}
                    onChange={changeCustomRequiredSectionContent}
                />
                <SubmitButton />
                <HtmlExportButton exportHtmlFile={exportHtmlFile} hide={newProject} disable={disableExport}/>
            </div>
        </div>
    );
};

type CheckBoxHeapProps = {
    checkboxTopics: string[],
    sections: PrivacyPolicySection[],
    unsavedSections: PrivacyPolicySection[],
    onChange: Function,
};

function CheckBoxHeap(
    {checkboxTopics, sections, unsavedSections, onChange}: CheckBoxHeapProps
): $JSXIntrinsics<HTMLDivElement>[] {
    if (Object.keys(sections).length === 0) {
        return null;
    }

    let sectionArray: PrivacyPolicySection[] = [];
    let columnTopics: string[] = [];
    let checkBoxes: Symbol[] = [];

    Object.keys(sections).map((sectionId: string) => {
        sectionArray.push(sections[sectionId])
    });

    {sectionArray.map((section: PrivacyPolicySection) => {
        if (!columnTopics.includes(section.formTopic)) {
            columnTopics.push(section.formTopic)
        }
    })}

    // Sortieren der DSE-Bausteine nach ihrer 'hierarchicalClassification'
    sectionArray.sort(compare)

    checkBoxes.push(checkboxTopics.map((formTopic: string) => {
        return (
            <div key={formTopic} className={columnTopics.includes(formTopic) ? "three_item-33--inner" : "hide"}>
                <span
                    key={formTopic}
                    className={columnTopics.includes(formTopic) ? "" : "hide"}
                >
                    {formTopic}
                </span>
                {sectionArray.map((section: PrivacyPolicySection, index: number) => {
                    // Wenn die Überschrift des Bausteins nicht mit der iterierten Überschrift übereinstimmt
                    if (section.formTopic !== formTopic) {
                        return null;
                    }

                    return (
                        <div key={index}>
                            <Checkbox
                                key={`main_${section.privacyPolicySectionId}`}
                                sectionId={section.privacyPolicySectionId}
                                section={section}
                                checked={unsavedSections.hasOwnProperty(section.privacyPolicySectionId)}
                                onChange={onChange}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }));

    return checkBoxes;

    function compare(a: PrivacyPolicySection, b: PrivacyPolicySection): number {
        if ( a.hierarchicalClassification < b.hierarchicalClassification ) {
            return -1;
        }

        if ( a.hierarchicalClassification > b.hierarchicalClassification ) {
            return 1;
        }

        return 0;
    }
}

type DynamicTextColsProps = {
    sectionsFormTopics: string[],
    sections: PrivacyPolicySection[],
    onChange: Function,
};

function DynamicTextCols(
    {sectionsFormTopics, sections, onChange}: DynamicTextColsProps
): ?DynamicText[] {
    if (!sectionsFormTopics || sectionsFormTopics.length === 0) {
        return null;
    }

    return sectionsFormTopics.map((formTopic: string) => {
        return (
            <div key={formTopic}>
                <span className="span__edit-section">{formTopic}</span>
                <br />
                {Object.entries(sections).map(([sectionId: string, section: PrivacyPolicySection]) => {
                    if (section.formTopic !== formTopic || !section.isCustomizable) {
                        return null;
                    }

                    return (
                        <DynamicText
                            key={sectionId}
                            sectionId={sectionId}
                            section={section}
                            value={prepareSectionContent(sections[sectionId].sectionContent) ?? ''}
                            onChange={onChange}
                        />
                    );
                })}
            </div>
        );
    });

    function prepareSectionContent(sectionContent: string): ?string {
        const customizableContent = sectionContent.match(/[{]{2}.+[}]{2}/g);

        if (!customizableContent) {
            return null;
        }

        return customizableContent[0].slice(2, customizableContent.length - 3);
    }
}
