// @flow

export default function sortPrivacyPolicyForNewSubSection(
    privacyPolicy,
    newClassification,
    newSubClassification,
) {
    let sortedPrivacyPolicy = {};

    Object.keys(privacyPolicy).map((classification) => (
        Object.keys(privacyPolicy[classification]).map((subClassification) => (
            // Alle Bausteine die über dem neuen Sub-Baustein liegen, werden in der Position um eins erhöht
            parseInt(classification, 10) === parseInt(newClassification, 10) && subClassification >= newSubClassification
                ? sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [classification]: {
                        ...sortedPrivacyPolicy[classification],
                        [parseInt(subClassification, 10) + 1]: {
                            ...privacyPolicy[classification][subClassification],
                            hierarchicalSubClassification: parseInt(subClassification, 10) + 1,
                        },
                    },
                }
                : sortedPrivacyPolicy = {
                    ...sortedPrivacyPolicy,
                    [classification]: {
                        ...sortedPrivacyPolicy[classification],
                        [subClassification]: {
                            ...privacyPolicy[classification][subClassification],
                        },
                    },
                }
        ))
    ));

    return sortedPrivacyPolicy;
}
