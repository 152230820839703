// @flow

import React from "react";

type StaticTextInputProps = {
    textElement: {
        type: string,
        name: string,
        label: string,
        readonly: null|string,
        cssClass: string,
    },
    value: string,
    onChange: Function,
};

export function StaticText({textElement, value, onChange}: StaticTextInputProps): $JSXIntrinsics<HTMLFormElement> {
    return (
        <div className={textElement.cssClass}>
            <label key={textElement.label} htmlFor={textElement.name}>
                {textElement.label}
            </label>
            <input
                key={textElement.name}
                type={textElement.type}
                id={textElement.name}
                name={textElement.name}
                readOnly={textElement.readonly ?? false}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

type DynamicTextInputProps = {
    sectionId: string,
    section: PrivacyPolicySection,
    value: string,
    onChange: Function,
};

export function DynamicText(
    {sectionId, section, value, onChange}: DynamicTextInputProps
): $JSXIntrinsics<HTMLFormElement> {
    return (
        <>
            <label key={section.sectionTitle} htmlFor={sectionId}>{section.sectionTitle}</label>
            <textarea
                key={sectionId}
                id={sectionId}
                name={sectionId}
                rows={4}
                value={value}
                onChange={onChange}
                className="input__edit"
            />
        </>
    );
}

type SelectInputProps = {
    selectElement: {
        type: string,
        name: string,
        label: string,
        options: SelectOptionAttributes[],
        cssClass: string,
    },
    value: string,
    onChange: Function,
};

export function Select({selectElement, value, onChange}: SelectInputProps): $JSXIntrinsics<HTMLFormElement> {
    return (
        <div>
            <label key={selectElement.label} htmlFor={selectElement.name}>
                {selectElement.label}
            </label>
            <select
                key={selectElement.name}
                id={selectElement.name}
                name={selectElement.name}
                value={value}
                onChange={onChange}
            >
                <option value={''}>Bitte auswählen</option>
                {selectElement.options.map((customerOption: { name: string, value: string }) => (
                    <option key={customerOption.value} value={customerOption.value}>{customerOption.name}</option>
                ))}
            </select>
        </div>
    );
}

type RadioInputProps = {
    formElementKey: string,
    radioElement: {
        orderBehavior: string,
        type: string,
        name: string,
        radioButtons: RadioButtonAttributes[],
        cssClass: string,
    },
    checkedValue: null|string,
    onChange: Function,
};

export function RadioButtons(
    {formElementKey, radioElement, checkedValue, onChange}: RadioInputProps
): $JSXIntrinsics<HTMLFormElement> {
    return (
        <>
            <div key={formElementKey} className="input-radio__flex">
                <span className="two__span-section">{formElementKey}</span>
            </div>
            {radioElement.radioButtons.map((radioButton: RadioButtonAttributes) => (
                <div key={`${radioElement.name}_${radioButton.value}`} className="two__span-section-radio">
                   <input
                       key={radioElement.value}
                       type={radioElement.type}
                       id={radioButton.value}
                       name={radioElement.name}
                       value={radioButton.value}
                       onChange={onChange}
                       checked={checkedValue ? checkedValue === radioButton.value : false}
                   />
                    <label key={radioButton.label} htmlFor={radioButton.value}>
                        {radioButton.label}
                    </label>
                 </div>
            ))}
        </>
    );
}

type CheckboxInputProps = {
    sectionId: string,
    section: PrivacyPolicySection,
    checked: boolean,
    onChange: Function,
};

export function Checkbox(
    {sectionId, section, checked, onChange}: CheckboxInputProps
): $JSXIntrinsics<HTMLFormElement> {
    return (
        <div>
            <input
                key={sectionId}
                type="checkbox"
                id={sectionId}
                name={section.isCustomizable ? 'customRequired' : 'optional'}
                slot={section.isSubSection ? 'subSection' : 'mainSection'}
                title={section.sectionContent}
                onChange={onChange}
                checked={checked}
            />
            <label
                key={section.sectionTitle}
                className="checkbox"
                htmlFor={sectionId}
            >
                {section.sectionTitle}
            </label>
        </div>
    );
}
