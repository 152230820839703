// @flow

import React, {useEffect, useReducer} from "react";
import {Link} from "react-router-dom";
import Spinner from "../scss/img/spinner.gif";
import {initialUsers, userOverviewReducer} from "./reducer/userReducer";
import {loadUsersApiCall} from "../Api/UserApi";
import {formatDateStringToUTC} from "../Project/helper/dateFormatting";

export default function UserOverview(): ?$JSXIntrinsics<HTMLTableElement> {
    const [state, dispatch] = useReducer(userOverviewReducer, initialUsers());

    useEffect(() => {
        if (!state.areUsersLoaded) {
            loadUsers();
        }

        return () => {
            dispatch({});
        }
    }, [state.areUsersLoaded]);

    async function loadUsers(): void {
        const response = await loadUsersApiCall();

        dispatch({
            type: 'setUsers',
            status: response.status,
            users: response.users,
        });
    }

    /** *** Hodor *** **/
    if (!state.areUsersLoaded) {
        return <div className="spinner_wrapper"><img className="spinner" src={Spinner} alt="loading..." /></div>;
    }

    return (
        <div className="content__inner">
            <h1>Benutzer</h1>
            <table className="table table__user">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>E-Mail</th>
                    <th>Vorname</th>
                    <th>Nachname</th>
                    <th>Rolle</th>
                    <th>Registriert am</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                <TableContent users={state.users}/>
                </tbody>
            </table>
            <Link className="btn__add" to={`/user/form`}>Neuer Benutzer</Link>
        </div>
    );

}

type TableContentProps = {
    users: User[],
};

function TableContent({users}: TableContentProps): ReactDOM$HTMLElementJSXIntrinsic<UserRecord>[] {
    return (
        users.map((user: User) => (
            <UserRecord key={user.userId} user={user} />
        ))
    );
}

type UserRecordProps = {
    user: User,
};

function UserRecord({user}: UserRecordProps): $JSXIntrinsics<HTMLTableRowElement> {
    return (
        <tr>
            <td>{user.userId}</td>
            <td>{user.emailAddress}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.role}</td>
            <td>{formatDateStringToUTC(new Date(user.registrationDate))}</td>
            <td className="td__edit">
                <Link className="btn__edit" to={`/user/form/${user.userId}`}><span>edit</span></Link>
            </td>
        </tr>
    );
}
