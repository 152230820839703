// @flow

import React from "react";

type FormHeaderProps = {
    newProject: boolean,
};

export function FormHeader({newProject}: FormHeaderProps): $JSXIntrinsics<HTMLHeadingElement> {
    return newProject ? <h1>Projekt anlegen</h1> : <h1>Projekt bearbeiten</h1>;
}

export function SubmitButton(): $JSXIntrinsics<HTMLButtonElement> {
    return <button className="btn__save" type="submit">Speichern</button>;
}

type HtmlExportButtonProps = {
    hide: boolean,
    disable: boolean[],
    exportHtmlFile: Function,
};

export function HtmlExportButton(
    {
        exportHtmlFile,
        hide,
        disable,
    }: HtmlExportButtonProps
): ?$JSXIntrinsics<HTMLButtonElement> {
    return hide
        ? null
        : disable.includes(true)
            ? <button
                className="btn__downl-disabled"
                type="button"
                onClick={exportHtmlFile}
                disabled
            >DSE HTML-Export</button>
            : <button
                className="btn__downl"
                type="button"
                onClick={exportHtmlFile}
            >DSE HTML-Export</button>;
}
