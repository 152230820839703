// @flow

import {commandActionApi} from "../../Api/CommandApi";
import sortDeletedPrivacyPolicySections from "./sortDeletedPrivacyPolicySections";

export default async function deleteSection(
    hierarchicalClassification: number,
    hierarchicalSubClassification: number,
    privacyPolicySectionId: string,
    unsavedPrivacyPolicySections: {[key: number]: {[key: number]: PrivacyPolicySection}},
    savedPrivacyPolicySections: {[key: number]: {[key: number]: PrivacyPolicySection}},
    dispatch: function,
    isPrivacyPolicySaved: boolean,
    changePrivacyPolicySavedState: Function,
    event: SyntheticEvent<HTMLFormElement>,
) {
    event.preventDefault();
    if (!isPrivacyPolicySaved) {
        dispatch({
            type: 'setResponse',
            response: "Bitte speichern Sie das Formular, bevor Sie eine Sektion löschen.",
        });
        return;
    }

    let commands = [];

    if (hierarchicalSubClassification === 0) {
        Object.keys(unsavedPrivacyPolicySections[hierarchicalClassification]).map((item) => (
            commands.push({
                'command': 'delete_privacy_policy_section',
                'payload': {
                    privacyPolicySectionId: unsavedPrivacyPolicySections[hierarchicalClassification][item].privacyPolicySectionId,
                }
            })
        ));

        Object.keys(unsavedPrivacyPolicySections).map((classification) => (
            Object.keys(unsavedPrivacyPolicySections[classification]).map((subClassification) => (
                hierarchicalClassification < classification ?
                    commands.push({
                        'command': 'change_privacy_policy_hierarchical_classification',
                        'payload': {
                            'privacyPolicySectionId': unsavedPrivacyPolicySections[classification][subClassification].privacyPolicySectionId,
                            'hierarchicalClassification': parseInt(classification, 10) - 1,
                        }
                    }) : null
            ))
        ));

        let newUnsavedPrivacyPolicySections = {...unsavedPrivacyPolicySections};

        delete newUnsavedPrivacyPolicySections[hierarchicalClassification];

        const sortedUnsavedPrivacyPolicySections = sortDeletedPrivacyPolicySections(
            newUnsavedPrivacyPolicySections,
            hierarchicalClassification,
            hierarchicalSubClassification,
        );

        dispatch({
            type: 'setUnsavedPrivacyPolicy',
            receivedValues: sortedUnsavedPrivacyPolicySections,
        });

        let newSavedPrivacyPolicySections = {...savedPrivacyPolicySections};

        delete newSavedPrivacyPolicySections[hierarchicalClassification];

        const sortedSavedPrivacyPolicySections = sortDeletedPrivacyPolicySections(
            newSavedPrivacyPolicySections,
            hierarchicalClassification,
            hierarchicalSubClassification,
        );

        dispatch({
            type: 'setSavedPrivacyPolicy',
            receivedValues: sortedSavedPrivacyPolicySections,
        });

        const response = await commandActionApi(commands, '/privacy-policy/action');

        if (response.error) {
            dispatch({
                type: 'setResponse',
                response: response.errorMessages,
            });
            return;
        }
    }

    if (commands.length === 0) {
        commands.push({
            'command': 'delete_privacy_policy_section',
            'payload': {
                privacyPolicySectionId: privacyPolicySectionId,
            }
        });

        Object.keys(unsavedPrivacyPolicySections).map((classification) => (
            Object.keys(unsavedPrivacyPolicySections[classification]).map((subClassification) => (
                parseInt(classification, 10) === hierarchicalClassification && hierarchicalSubClassification < subClassification
                    ? commands.push({
                        'command': 'change_privacy_policy_hierarchical_sub_classification',
                        'payload': {
                            'privacyPolicySectionId': unsavedPrivacyPolicySections[classification][subClassification].privacyPolicySectionId,
                            'hierarchicalSubClassification': parseInt(subClassification, 10) - 1,
                        }
                    })
                    : null
            ))
        ));

        const response = await commandActionApi(commands, '/privacy-policy/action');

        if (response.error) {
            dispatch({
                type: 'setResponse',
                response: response.errorMessages,
            });
            return;
        }

        let newUnsavedPrivacyPolicySections = {...unsavedPrivacyPolicySections};

        delete newUnsavedPrivacyPolicySections[hierarchicalClassification][hierarchicalSubClassification];

        const sortedUnsavedPrivacyPolicySections = sortDeletedPrivacyPolicySections(
            newUnsavedPrivacyPolicySections,
            hierarchicalClassification,
            hierarchicalSubClassification,
        );

        dispatch({
            type: 'setUnsavedPrivacyPolicy',
            receivedValues: sortedUnsavedPrivacyPolicySections,
        });

        let newSavedPrivacyPolicySections = {...savedPrivacyPolicySections}

        delete newSavedPrivacyPolicySections[hierarchicalClassification][hierarchicalSubClassification]

        const sortedSavedPrivacyPolicySections = sortDeletedPrivacyPolicySections(
            newSavedPrivacyPolicySections,
            hierarchicalClassification,
            hierarchicalSubClassification,
        );

        dispatch({
            type: 'setSavedPrivacyPolicy',
            receivedValues: sortedSavedPrivacyPolicySections,
        });
    }
}