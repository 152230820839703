// @flow

import React from "react";
import {Link} from "react-router-dom";

type SideNavProps = {
    navLocation: string,
    userRole: string,
}

export default function SideNav({navLocation, userRole}: SideNavProps) {
    function determineActiveLink(navItem: string): string {
        if (navLocation === navItem) {
            return 'active';
        }

        return '';
    }

    if (userRole !== 'Admin') {
        return (
            <div className="side-nav">
                <ul>
                    <li><Link className={determineActiveLink('project')} to='/project/overview'>Projekte</Link></li>
                    <li><Link className={determineActiveLink('customer')} to='/customer/overview'>Firmen</Link></li>
                    <li><Link className={determineActiveLink('privacy-policy')} to='/privacy-policy/overview'>DSE</Link></li>
                </ul>
            </div>
        );
    }

    return (
        <div className="side-nav">
            <ul>
                <li><Link className={determineActiveLink('project')} to='/project/overview'>Projekte</Link></li>
                <li><Link className={determineActiveLink('customer')} to='/customer/overview'>Firmen</Link></li>
                <li><Link className={determineActiveLink('privacy-policy')} to='/privacy-policy/overview'>DSE</Link></li>
                <li><Link className={determineActiveLink('user')} to='/user/overview'>Benutzer</Link></li>
            </ul>
        </div>
    );
}
