// @flow

import NotAuthorizedError from "../../Authorization/NotAuthorizedError";

export const initialUsers = (): UserOverviewState => {
    return {
        areUsersLoaded: false,
        users: [],
    };
};

export const userOverviewReducer = (state: UserOverviewState, action: UserOverviewAction): UserOverviewState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setUsers':
            return {
                areUsersLoaded: true,
                users: action.users,
            }
        case 'cleanup':
            return {
                ...initialUsers(),
            }
        default:
            return {
                // TODO: Hier kann sicherlich noch nen Errorhandling hin, doch erstmal nur Endlosschleifen verhindern
                ...state,
                areUsersLoaded: true,
            };
    }
};

export const initialUser = (): UserFormState => {
    return {
        userRolesLoaded: false,
        userRoles: [],
        userLoaded: false,
        savedUser: {
            userId: "",
            emailAddress: "",
            firstName: "",
            lastName: "",
            role: "",
            password: "",
            registrationDate: "",
        },
        unsavedUser: {
            userId: "",
            emailAddress: "",
            firstName: "",
            lastName: "",
            role: "",
            password: "",
            registrationDate: "",
        },
        formTextLabels: {
            firstName: {
                label: "Vorname",
            },
            lastName: {
                label: "Nachname",
            },
            emailAddress: {
                label: "E-Mail-Adresse",
            },
            password: {
                label: "Passwort",
            },
        },
        userResponse: {
            createUser: {
                body: {},
                errorMessage: "",
            },
            emailAddress: {
                body: {},
                errorMessage: "",
            },
            firstName: {
                body: {},
                errorMessage: "",
            },
            lastName: {
                body: {},
                errorMessage: "",
            },
            password: {
                body: {},
                errorMessage: "",
            },
            role: {
                body: {},
                errorMessage: "",
            },
        }
    };
};

export const userFormReducer = (state: UserFormState, action: UserFormAction): UserFormState => {
    if (action.status === 401) throw new NotAuthorizedError('Not authorized');

    switch (action.type) {
        case 'setRoles':
            return {
                ...state,
                userRolesLoaded: true,
                userRoles: action.userRoles,
            }
        case 'setUserValues':
            return {
                ...state,
                userLoaded: true,
                savedUser: {
                    ...state.savedUser,
                    userId: action.receivedValues.userId,
                    emailAddress: action.receivedValues.emailAddress,
                    firstName: action.receivedValues.firstName,
                    lastName: action.receivedValues.lastName,
                    role: action.receivedValues.role,
                    registrationDate: action.receivedValues.registrationDate,
                },
                unsavedUser: {
                    ...state.unsavedUser,
                    userId: action.receivedValues.userId,
                    emailAddress: action.receivedValues.emailAddress,
                    firstName: action.receivedValues.firstName,
                    lastName: action.receivedValues.lastName,
                    role: action.receivedValues.role,
                    registrationDate: action.receivedValues.registrationDate,
                },
            }
        case 'setValue':
            return {
                ...state,
                unsavedUser: {
                    ...state.unsavedUser,
                    [action.property]: action.value,
                },
            }
        case 'setResponse':
        return {
            ...state,
            userResponse: {
                ...state.userResponse,
                ...action.userResponse,
            }
        }
        case 'cleanup':
            return {
                ...initialUser(),
            }
        default:
            return {
                // TODO: Hier kann sicherlich noch nen Errorhandling hin, doch erstmal nur Endlosschleifen verhindern
                ...state,
                userLoaded: true,
                userRolesLoaded: true,
            };
    }
};
